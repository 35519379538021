<template>
  <div v-if="isMobile">
    <LoginMovil />
  </div>
  <div v-else>
    <LoginWeb />
  </div>
</template>

<script lang="js">
import LoginWeb from './LoginWeb.vue';
import LoginMovil from './LoginMovil.vue';

export default {
    name: "Login",
    data: () => ({
        isMobile: false,
    }),
    mounted() {
        this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        console.log(this.isMobile);
    },
    components: { LoginMovil, LoginWeb }
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  background: #fff;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
}
</style>
