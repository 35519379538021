<template>
  <v-app>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <Snackbar />
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar";
export default {
  name: "App",
  components: {
    Snackbar,
  },
  data: () => ({}),
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
