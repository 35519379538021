<template>
  <div class="text-center">
    <div>
      <v-bottom-sheet
        v-model="stateSheetInfo"
        @click:outside="cerrarDialog"
        @keydown.esc="cerrarDialog"
      >
        <v-sheet style="height: 80vh">
          <div>
            <v-divider></v-divider>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="red"
              grow
            >
              <v-tab>Información</v-tab>
              <v-tab>Viaje Inicial</v-tab>
              <v-tab>Viaje Regreso</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-row class="ma-3">
                  <v-col cols="3">
                    <v-card
                      outlined
                      style="height: 68vh"
                      class="text-center pa-5"
                    >
                      <div v-if="autorizar('Agregar')">
                        <div
                          v-if="dataGerencia?.bitacora === null ? true : false"
                        >
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="addBitacora('Agregar')"
                                tile
                                outlined
                                width="220"
                                class="white--text"
                                color="info"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon left>mdi-upload</v-icon>Subir Bitacora
                              </v-btn>
                            </template>
                            <span>Subir Archivo de Bitacora</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <div v-if="autorizar('Modificar')">
                        <div
                          v-if="dataGerencia?.bitacora !== null ? true : false"
                        >
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                :disabled="
                                  rol != 'Administrador' && rol != 'CMC'
                                    ? true
                                    : false
                                "
                                @click="addBitacora('Editar')"
                                tile
                                outlined
                                width="220"
                                class="white--text"
                                color="info"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon left>mdi-pencil</v-icon>Modificar
                                Bitacora
                              </v-btn>
                            </template>
                            <span>Modificar Archivo de Bitacora</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-spacer class="ma-5"></v-spacer>
                      <div>
                        <div
                          v-if="dataGerencia?.bitacora !== null ? true : false"
                        >
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="dataGerencia.bitacora !== null"
                                :href="dataGerencia.bitacora"
                                target="blank"
                                tile
                                outlined
                                width="220"
                                class="white--text"
                                color="info"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon left>mdi-eye</v-icon>Bitacora
                              </v-btn>
                            </template>
                            <span>Mostrar Archivo de Bitacora</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-spacer class="ma-5"></v-spacer>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :loading="loadingButtonVer"
                            @click="verPDF"
                            tile
                            outlined
                            width="220"
                            class="white--text"
                            color="info"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left>mdi-eye</v-icon>Gerenciamiento
                          </v-btn>
                        </template>
                        <span>Mostrar Archivo de Gerenciamiento</span>
                      </v-tooltip>
                      <v-spacer class="ma-5"></v-spacer>
                      <div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :loading="loadingButton"
                              @click="malaOperacion"
                              tile
                              outlined
                              width="220"
                              class="white--text"
                              color="warning"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left>mdi-alert</v-icon>Mala Operación
                            </v-btn>
                          </template>
                          <span>Mostrar Evidencias de Mala Operación</span>
                        </v-tooltip>
                      </div>

                      <v-spacer class="ma-5"></v-spacer>
                      <div v-if="autorizar('Modificar')">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="autorizar('Modificar')"
                              :disabled="
                                tipoMatriz != 'EnProceso' ? true : false
                              "
                              @click="terminarModal('terminar')"
                              tile
                              outlined
                              width="220"
                              class="white--text"
                              color="green"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left>mdi-check</v-icon>Terminar
                            </v-btn>
                          </template>
                          <span>Terminar Gerenciamiento</span>
                        </v-tooltip>
                      </div>
                      <v-spacer class="ma-5"></v-spacer>
                      <div v-if="autorizar('Eliminar')">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="
                                tipoMatriz != 'EnProceso' ? true : false
                              "
                              @click="terminarModal('cancelar')"
                              tile
                              outlined
                              width="220"
                              class="white--text"
                              color="grey"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left>mdi-close</v-icon>Cancelar
                            </v-btn>
                          </template>
                          <span>Cancelar Gerenciamiento</span>
                        </v-tooltip>
                      </div>
                      <v-spacer class="ma-5"></v-spacer>
                      <div v-if="autorizar('Modificar')">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="
                                tipoMatriz === 'EnProceso' ? true : false
                              "
                              @click="pedidoApertura"
                              tile
                              outlined
                              width="220"
                              class="white--text"
                              color="red"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left>mdi-lock-open-check-outline</v-icon
                              >Solicitar Apertura
                            </v-btn>
                          </template>
                          <span>Solicitar Apertura del Servicio</span>
                        </v-tooltip>
                      </div>
                      <v-spacer class="ma-5"></v-spacer>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="administrarServicio"
                            tile
                            outlined
                            width="220"
                            class="white--text"
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left>mdi-shield-crown-outline</v-icon
                            >Administrar
                          </v-btn>
                        </template>
                        <span>Administrar Gerenciamiento</span>
                      </v-tooltip>
                    </v-card>
                  </v-col>
                  <v-col cols="9">
                    <v-card outlined style="height: 68vh">
                      <v-row no-gutters class="ma-5">
                        <v-col cols="3" class="text-h5 pa-2">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            v-model="folioActualizable"
                            label="Folio del Servicio"
                            :value="dataGerencia.folio_servicio"
                            outlined
                            :loading="loadingGuardarFolio"
                            @blur="editarFolio"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3" class="text-h5 pa-2">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            label="Cliente"
                            v-model="dataGerencia.cliente"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3" class="text-h5 pa-2">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            @change="cambiarFecha"
                            dense
                            label="Fecha"
                            v-model="fechaFormateada"
                            outlined
                            type="date"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3" class="text-h5 pa-2">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            @change="cambiarHora"
                            label="Hora"
                            v-model="horaFormateada"
                            outlined
                            type="time"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" class="text-h5 pa-1">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            label="Origen"
                            v-model="dataGerencia.origen"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" class="text-h5 pa-1">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            label="Destino"
                            v-model="dataGerencia.destino"
                            outlined
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="4" class="text-h5 pa-1">
                          <v-text-field
                            type="date"
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            label="Termino de Servicio"
                            v-model="fecha_termino"
                            outlined
                            @change="editarFechaTermino"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" class="text-h5 pa-1">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            v-model="kmActualizable"
                            label="Kilometros Recorridos"
                            :value="dataGerencia.km_recorridos"
                            outlined
                            :loading="loadingGuardarKM"
                            @blur="editarKM"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="4" class="text-h5 pa-1">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            label="Operador"
                            v-model="dataGerencia.conductor"
                            outlined
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" class="text-h5 pa-1">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            label="Teléfono"
                            v-model="dataGerencia.telefono"
                            outlined
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" class="text-h5 pa-1">
                          <v-select
                            v-model="economicoActualizable"
                            :items="listaVehiculos"
                            label="No. ECO"
                            item-text="no_eco"
                            item-value="id"
                            dense
                            outlined
                            @change="editarNoEco"
                          ></v-select>
                          <!-- <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            label="No. ECO"
                            v-model="economicoActualizable"
                            :value="datosEditar.no_eco"
                            outlined
                            @blur="editarNoEco"
                          >
                          </v-text-field> -->
                        </v-col>
                        <v-col cols="4" class="text-h5 pa-1">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            label="Tipo de Vehiculo"
                            v-model="dataGerencia.tipo_vehiculo"
                            outlined
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" class="text-h5 pa-1">
                          <v-text-field
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            dense
                            label="Placa"
                            v-model="dataGerencia.placa"
                            outlined
                            readonly
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" class="text-h5 pa-1">
                          <v-textarea
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            v-model="txtObservaciones"
                            outlined
                            name="input-7-4"
                            label="Observaciones"
                            :value="dataGerencia.observaciones"
                            @blur="editarObservaciones"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <!-- VIAJE INICIAL -->
              <v-tab-item>
                <v-card outlined class="ma-5 cardScroll" height="65vh">
                  <v-data-table
                    class="cardSection"
                    :headers="headers"
                    :items="viajeInicia"
                    :items-per-page="15"
                    loading-text="Cargando Datos... Por favor espere"
                    dense
                  >
                    <!-- <template v-slot:item.percnota="{ item }">
                      <v-checkbox v-key="item" v-model="item.percnota"></v-checkbox>
                    </template> -->
                    <template v-slot:item.actions="{ item }">
                      <div class="d-inline">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="
                                tipoMatriz != 'EnProceso'
                                  ? true
                                  : false || item.tipo_check === 'Origen'
                                  ? true
                                  : false
                              "
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="evidenciasCheck(item)"
                              color="warning"
                            >
                              <v-icon>mdi-upload</v-icon>
                            </v-btn>
                          </template>
                          <span>Subir Evidencias</span>
                        </v-tooltip>
                      </div>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="autorizar('Modificar')"
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="cambiarRuta(item, 'Inicial')"
                            class="white--text"
                            color="error"
                          >
                            <v-icon>mdi-arrow-decision-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Agregar CheckPoints</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="autorizar('Eliminar')"
                            :disabled="
                              tipoMatriz != 'EnProceso' ||
                              item.tipo_check === 'Origen' ||
                              item.tipo_check === 'Destino'
                                ? true
                                : false
                            "
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="quitarCheckpoint(item, 'Inicial')"
                            class="white--text"
                            color="error"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar CheckPoints</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.tiempo_extra="{ item }">
                      <v-chip
                        :disabled="
                          tipoMatriz != 'EnProceso' ||
                          item.tipo_check === 'Origen' ||
                          item.tipo_check === 'Destino'
                            ? true
                            : false
                        "
                        @click="tiempoExtra(item, 'inicial')"
                        label
                        class="white--text"
                        small
                        color="green"
                        >{{ item.tiempo_extra }}</v-chip
                      >
                    </template>
                    <template v-slot:item.tiempo_muerto="{ item }">
                      <v-chip
                        :disabled="tipoMatriz != 'EnProceso' ? true : false"
                        @click="tiempoMuerto(item, 'inicial')"
                        label
                        class="white--text"
                        small
                        color="red"
                        >{{ item.tiempo_muerto }}</v-chip
                      >
                    </template>
                    <template v-slot:item.tiempo_descanso="{ item }">
                      <v-chip
                        :disabled="tipoMatriz != 'EnProceso' ? true : false"
                        @click="tiempoDescanso(item, 'inicial')"
                        label
                        class="white--text"
                        small
                        color="grey"
                        >{{ item.tiempo_descanso }}</v-chip
                      >
                    </template>
                    <template v-slot:item.fecha_salida="{ item }">
                      <v-chip
                        :disabled="tipoMatriz != 'EnProceso' ? true : false"
                        v-if="
                          item.tipo_check === 'Origen' &&
                          item.fecha_salida === 'Origen sin Fecha de Salida'
                        "
                        @click="fechaSalida(item, 'inicial')"
                        label
                        class="white--text"
                        small
                        color="grey"
                        >Agregar Fecha de Salida</v-chip
                      >
                      <v-chip
                        :disabled="tipoMatriz != 'EnProceso' ? true : false"
                        v-if="
                          item.tipo_check === 'Origen' &&
                          item.fecha_salida != 'Origen sin Fecha de Salida'
                        "
                        @click="fechaSalida(item, 'inicial')"
                        label
                        class="white--text"
                        small
                        color="grey"
                        >{{ item.fecha_salida }}</v-chip
                      >
                      <span v-if="item.tipo_check != 'Origen'">{{
                        item.fecha_salida
                      }}</span>
                    </template>
                    <template v-slot:item.tr_salida="{ item }">
                      <div v-if="item.tipo_check === 'Destino'">
                        <v-chip
                          :disabled="tipoMatriz != 'EnProceso' ? true : false"
                          @click="
                            item.tr_salida.mensaje != 'Sin Hora'
                              ? fechaRSalida(
                                  item,
                                  'inicial',
                                  'modificar',
                                  'Destino'
                                )
                              : fechaRSalida(
                                  item,
                                  'inicial',
                                  'insertar',
                                  'Destino'
                                )
                          "
                          label
                          class="ma-2"
                          v-bind:class="[
                            item.tr_salida.fondo,
                            item.tr_salida.letra,
                          ]"
                          small
                          >{{ item.tr_salida.mensaje }}</v-chip
                        >
                      </div>
                      <div v-else>
                        <v-chip
                          :disabled="tipoMatriz != 'EnProceso' ? true : false"
                          @click="
                            item.tr_salida.mensaje != 'Sin Hora'
                              ? fechaRSalida(item, 'inicial', 'modificar')
                              : fechaRSalida(item, 'inicial', 'insertar')
                          "
                          label
                          class="ma-2"
                          v-bind:class="[
                            item.tr_salida.fondo,
                            item.tr_salida.letra,
                          ]"
                          small
                          >{{ item.tr_salida.mensaje }}</v-chip
                        >
                      </div>
                    </template>
                    <template v-slot:item.tr_llegada="{ item }">
                      <v-chip
                        :disabled="
                          tipoMatriz != 'EnProceso'
                            ? true
                            : false || item.tipo_check === 'Origen'
                            ? true
                            : false
                        "
                        @click="
                          item.tr_llegada.mensaje != 'Sin Hora'
                            ? fechaRLlegada(item, 'inicial', 'modificar')
                            : fechaRLlegada(item, 'inicial', 'insertar')
                        "
                        label
                        class="ma-2"
                        v-bind:class="[
                          item.tr_llegada.fondo,
                          item.tr_llegada.letra,
                        ]"
                        small
                        >{{ item.tr_llegada.mensaje }}</v-chip
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
              <!-- VIAJE REGRESO -->
              <v-tab-item>
                <v-card outlined class="ma-5 cardScroll" height="65vh">
                  <v-data-table
                    class="cardSection"
                    :headers="headers"
                    :items="ViajeRegreso"
                    :items-per-page="15"
                    loading-text="Cargando Datos... Por favor espere"
                    dense
                  >
                    <!-- <template v-slot:item.percnota="{ item }">
                      <v-checkbox v-key="item" v-model="checkbox"></v-checkbox>
                    </template> -->
                    <template v-slot:item.actions="{ item }">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="
                              tipoMatriz != 'EnProceso'
                                ? true
                                : false || item.tipo_check === 'Destino'
                                ? true
                                : false
                            "
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="evidenciasCheck(item)"
                            color="warning"
                          >
                            <v-icon>mdi-upload</v-icon>
                          </v-btn>
                        </template>
                        <span>Subir Evidencias</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="autorizar('Modificar')"
                            :disabled="tipoMatriz != 'EnProceso' ? true : false"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="cambiarRuta(item, 'Regreso')"
                            class="white--text"
                            color="error"
                          >
                            <v-icon>mdi-arrow-decision-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Agregar CheckPoints</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="autorizar('Modificar')"
                            :disabled="
                              tipoMatriz != 'EnProceso' ||
                              item.tipo_check === 'Origen' ||
                              item.tipo_check === 'Destino'
                                ? true
                                : false
                            "
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="quitarCheckpoint(item, 'Regreso')"
                            class="white--text"
                            color="error"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar CheckPoints</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.tiempo_extra="{ item }">
                      <div>
                        <v-chip
                          :disabled="
                            tipoMatriz != 'EnProceso' ||
                            item.tipo_check === 'Origen' ||
                            item.tipo_check === 'Destino'
                              ? true
                              : false
                          "
                          @click="tiempoExtra(item, 'regreso')"
                          label
                          class="white--text"
                          small
                          color="green"
                          >{{ item.tiempo_extra }}</v-chip
                        >
                      </div>
                    </template>
                    <template v-slot:item.tiempo_muerto="{ item }">
                      <div>
                        <v-chip
                          :disabled="tipoMatriz != 'EnProceso' ? true : false"
                          @click="tiempoMuerto(item, 'regreso')"
                          label
                          class="white--text"
                          small
                          color="red"
                          >{{ item.tiempo_muerto }}</v-chip
                        >
                      </div>
                    </template>
                    <template v-slot:item.tiempo_descanso="{ item }">
                      <div>
                        <v-chip
                          :disabled="tipoMatriz != 'EnProceso' ? true : false"
                          @click="tiempoDescanso(item, 'regreso')"
                          label
                          class="white--text"
                          small
                          color="grey"
                          >{{ item.tiempo_descanso }}</v-chip
                        >
                      </div>
                    </template>
                    <template v-slot:item.fecha_salida="{ item }">
                      <div>
                        <v-chip
                          :disabled="tipoMatriz != 'EnProceso' ? true : false"
                          v-if="
                            item.tipo_check === 'Destino' &&
                            item.fecha_salida === 'Origen sin Fecha de Salida'
                          "
                          @click="fechaSalida(item, 'regreso')"
                          label
                          class="white--text"
                          small
                          color="grey"
                          >Agregar Fecha de Salida</v-chip
                        >
                        <v-chip
                          :disabled="tipoMatriz != 'EnProceso' ? true : false"
                          v-if="
                            item.tipo_check === 'Destino' &&
                            item.fecha_salida != 'Origen sin Fecha de Salida'
                          "
                          @click="fechaSalida(item, 'regreso')"
                          label
                          class="white--text"
                          small
                          color="grey"
                          >{{ item.fecha_salida }}</v-chip
                        >
                        <span v-if="item.tipo_check != 'Destino'">{{
                          item.fecha_salida
                        }}</span>
                      </div>
                    </template>
                    <template v-slot:item.tr_salida="{ item }">
                      <div v-if="item.tipo_check === 'Origen'">
                        <v-chip
                          :disabled="tipoMatriz != 'EnProceso' ? true : false"
                          @click="
                            item.tr_salida.mensaje != 'Sin Hora'
                              ? fechaRSalida(
                                  item,
                                  'regreso',
                                  'modificar',
                                  'Destino'
                                )
                              : fechaRSalida(
                                  item,
                                  'regreso',
                                  'insertar',
                                  'Destino'
                                )
                          "
                          label
                          class="ma-2"
                          v-bind:class="[
                            item.tr_salida.fondo,
                            item.tr_salida.letra,
                          ]"
                          small
                          >{{ item.tr_salida.mensaje }}</v-chip
                        >
                      </div>
                      <div v-else>
                        <v-chip
                          :disabled="tipoMatriz != 'EnProceso' ? true : false"
                          @click="
                            item.tr_salida.mensaje != 'Sin Hora'
                              ? fechaRSalida(item, 'regreso', 'modificar')
                              : fechaRSalida(item, 'regreso', 'insertar')
                          "
                          label
                          class="ma-2"
                          v-bind:class="[
                            item.tr_salida.fondo,
                            item.tr_salida.letra,
                          ]"
                          small
                          >{{ item.tr_salida.mensaje }}</v-chip
                        >
                      </div>
                    </template>
                    <template v-slot:item.tr_llegada="{ item }">
                      <div>
                        <v-chip
                          :disabled="
                            tipoMatriz != 'EnProceso' ||
                            item.tipo_check === 'Destino'
                              ? true
                              : false
                          "
                          @click="
                            item.tr_llegada.mensaje != 'Sin Hora'
                              ? fechaRLlegada(item, 'regreso', 'modificar')
                              : fechaRLlegada(item, 'regreso', 'insertar')
                          "
                          label
                          class="ma-2"
                          v-bind:class="[
                            item.tr_llegada.fondo,
                            item.tr_llegada.letra,
                          ]"
                          small
                          >{{ item.tr_llegada.mensaje }}</v-chip
                        >
                      </div>
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
              <div v-if="loading">
                <v-overlay absolute :opacity="0.2" :value="true">
                  <v-progress-circular
                    indeterminate
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
              </div>
            </v-tabs-items>
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <ShowCheck
        :tipo_check="tipo_check"
        :tipoViaje="tipoViaje"
        :datosShow="datosShow"
        :tipo="tipoModal"
        :tipoAccion="tipoAccion"
        :stateShowCheck="stateShowCheck"
        @cerrarShowCheck="cerrarModal"
        @actualizar="datosGerenciamiento"
      />
      <DialogConfirmar
        :datosShow="dataGerencia"
        :tipo="tipoModal"
        :stateDialogConfirmar="stateDialogConfirmar"
        @cerrarDialogConfirmar="cerrarModal"
        @actualizar="cerrarGerenciamientoModal"
      />
      <DialogAddBitacora
        :datosEditar="dataGerencia"
        :tipo="tipoModal"
        :stateDialogBitacora="stateDialogBitacora"
        @cerrarDialogBitacora="cerrarModal"
        @actualizar="datosGerenciamiento"
      />
      <DialogChangeRoute
        :tipo_viaje="tipoViaje"
        :dataGerencia="dataGerencia"
        :datosEditar="datosEditarCheck"
        :stateDialogChangeRoute="stateDialogChangeRoute"
        @cerrarDialogBitacora="cerrarModal"
        @actualizar="datosGerenciamiento"
      />
      <DialogAddMalaOperacion
        :stateDialogMO="stateDialoMO"
        @cerrarDialogMP="cerrarModal"
        :datosEditar="dataGerencia"
        :tipoMatriz="tipoMatriz"
      />
      <DialogAddEvidenciaCheck
        :stateDialogEviCheck="stateDialogEviCheck"
        :datosEditar="datosEditarCheck"
        :soloConsulta="soloConsulta"
        @cerrarDialog="cerrarModal"
      />
      <DialogEliminarRegistros
        :tipo="'eliminar'"
        :stateDialogEliminar="stateDialogEliminar"
        :id="idCheckpoint"
        :url="rutaEliminar"
        @cerrarModalEliminar="cerrarModal"
        @actualizar="datosGerenciamiento"
      />
      <DialogAdminServicio
        :stateDialog="stateDialogAdminServicio"
        :datosEditar="dataGerencia"
        @cerrarDialog="cerrarModal"
      />
    </div>
  </div>
</template>
<script>
import EventServices from "@/js/EventServices.js";
import Mixin from "@/js/Mixin.js";

import { mapMutations } from "vuex";
import ShowCheck from "@/components/Gerenciamientos/ShowCheck";
import DialogConfirmar from "@/components/Gerenciamientos/DialogConfirmar";
import DialogAddBitacora from "@/components/Gerenciamientos/DialogAddBitacora";
import DialogChangeRoute from "@/components/Gerenciamientos/DialogChangeRoute";
import DialogAddMalaOperacion from "@/components/Gerenciamientos/DialogAddMalaOperacion";
import DialogAddEvidenciaCheck from "@/components/Gerenciamientos/DialogAddEvidenciaCheck";
import DialogEliminarRegistros from "@/components/Generales/DialogEliminarRegistros";
import DialogAdminServicio from "@/components/Gerenciamientos/DialogAdminServicio";

export default {
  mixins: [Mixin],
  name: "SheetInfoGerenciamiento",
  components: {
    DialogAdminServicio,
    ShowCheck,
    DialogConfirmar,
    DialogAddBitacora,
    DialogChangeRoute,
    DialogAddMalaOperacion,
    DialogAddEvidenciaCheck,
    DialogEliminarRegistros,
  },
  props: {
    stateSheetInfo: Boolean,
    datosEditar: Object,
    tipoMatriz: String,
    tipoSheet: String,
  },
  data: () => ({
    stateDialogAdminServicio: false,
    loadingButtonVer: false,
    fecha_termino: null,
    checkbox: false,
    horaFormateada: null,
    fechaFormateada: null,
    loadingObservaciones: false,
    txtObservaciones: null,
    kmActualizable: null,

    rutaEliminar: "",
    stateDialogChangeRoute: false,
    loading: false,
    //MALA OPERACION
    stateDialoMO: false,
    terminoServicio: null,
    //---------------
    //EVIDENCIAS CHECKPOINT
    soloConsulta: false,
    stateDialogEviCheck: false,
    loadingGuardarKM: false,
    idCheckpoint: 0,
    folioActualizable: 0,
    economicoActualizable: {},
    loadingGuardarFolio: false,
    tipo_check: null,
    rol: null,
    tipoViaje: "",
    tipoAccion: "",
    datosShow: {},
    stateShowCheck: false,
    dataGerencia: {},
    viajeInicia: [],
    ViajeRegreso: [],
    datosVehiculo: {},
    datosOperador: {},
    tipoModal: "",
    datosEditarCheck: {},
    stateDialogConfirmar: false,
    stateDialogEliminar: false,
    stateDialogBitacora: false,
    tab: null,
    loadingButton: false,
    headers: [
      { text: "CheckPoint", value: "checkpoint", width: 150 },
      {
        text: "Extra",
        value: "tiempo_extra",
        width: 50,
        align: "center",
      },
      {
        text: "Perdido",
        value: "tiempo_muerto",
        width: 50,
        align: "center",
      },
      {
        text: "Descanso",
        value: "tiempo_descanso",
        width: 50,
        align: "center",
      },
      {
        text: "Tiempo Recorre",
        value: "tiempo_recorrido",
        width: 130,
        align: "center",
      },
      {
        text: "Fecha/Hora Llegada",
        value: "fecha_llegada",
        width: 150,
        align: "center",
      },
      {
        text: "Hora Real Llegada",
        value: "tr_llegada",
        width: 100,
        align: "center",
      },
      {
        text: "Fecha/Hora Salida",
        value: "fecha_salida",
        width: 100,
        align: "center",
      },
      {
        text: "Hora Real Salida",
        value: "tr_salida",
        width: 100,
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        width: 130,
        align: "center",
      },
    ],

    //Listas para editar
    listaOperadors: [],
    listaVehiculos: [],
    listaClientes: [],
  }),
  mounted() {
    this.rol = this.$store.state.datosUsuariosState.rolS;
  },
  methods: {
    ...mapMutations(["SNACKBAR_MUTATION_MOSTRAR"]),

    async obtenerListasSelect() {
      EventServices.listaSelectCientes().then((response) => {
        this.listaClientes = response.data.data;
      });
      EventServices.listaSelectOperador().then((response) => {
        this.listaOperadors = response.data.data;
      });
      EventServices.listaSelectVehiculos().then((response) => {
        this.listaVehiculos = response.data.data;
      });
    },

    pedidoApertura: function () {
      this.stateDialogConfirmar = true;
      this.tipoModal = "apertura";
    },
    administrarServicio: function () {
      console.log("first");
      this.stateDialogAdminServicio = true;
    },
    malaOperacion: function () {
      this.stateDialoMO = true;
    },

    evidenciasCheck: function (item) {
      this.stateDialogEviCheck = true;
      this.datosEditarCheck = item;
      if (item.estatus !== 1) {
        this.soloConsulta = true;
      }
    },
    tiempoMuerto: function (item, tipoViaje) {
      this.stateShowCheck = true;
      this.datosShow = JSON.parse(JSON.stringify(item));
      this.tipoModal = "tiempo_muerto";
      this.tipoViaje = tipoViaje;
    },
    tiempoDescanso: function (item, tipoViaje) {
      this.stateShowCheck = true;
      this.datosShow = JSON.parse(JSON.stringify(item));
      this.tipoModal = "tiempo_descanso";
      this.tipoViaje = tipoViaje;
    },
    tiempoExtra: function (item, tipoViaje) {
      this.stateShowCheck = true;
      this.datosShow = JSON.parse(JSON.stringify(item));
      this.tipoModal = "tiempo_extra";
      this.tipoViaje = tipoViaje;
    },
    fechaSalida: function (item, tipoViaje) {
      this.stateShowCheck = true;
      this.datosShow = JSON.parse(JSON.stringify(item));
      this.tipoModal = "fecha_salida";
      this.tipoViaje = tipoViaje;
    },
    fechaRSalida: function (item, tipoViaje, tipoAccion, tipo_check) {
      this.stateShowCheck = true;
      this.datosShow = JSON.parse(JSON.stringify(item));
      this.tipoModal = "tr_salida";
      this.tipoViaje = tipoViaje;
      this.tipoAccion = tipoAccion;
      this.tipo_check = tipo_check;
    },
    fechaRLlegada: function (item, tipoViaje, tipoAccion) {
      console.log("item", item);
      this.stateShowCheck = true;
      this.datosShow = JSON.parse(JSON.stringify(item));
      this.tipoModal = "tr_llegada";
      this.tipoViaje = tipoViaje;
      this.tipoAccion = tipoAccion;
    },
    cerrarDialog: function () {
      this.$emit("cerrarSheetInfo", false);
    },
    click(event, { item }) {
      this.stateShowCheck = true;
      this.datosShow = item;
    },
    terminarModal(tipo) {
      this.stateDialogConfirmar = true;
      this.tipoModal = tipo;
    },
    addBitacora(tipo) {
      this.stateDialogBitacora = true;
      this.tipoModal = tipo;
    },
    cambiarRuta(item, tipo_Viaje) {
      console.log(item);
      this.tipoViaje = tipo_Viaje;
      this.datosEditarCheck = item;
      this.stateDialogChangeRoute = true;
    },
    cambiarFecha() {
      console.log(this.fechaFormateada);
      let datos = {
        id: this.datosEditar.id,
        fecha: this.fechaFormateada,
      };
      EventServices.cambiarFecha(datos)
        .then((response) => {
          console.log("data", response);
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: "Fecha Actualizada",
            color: "success",
          });
          this.$emit("actualizar");
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: error,
            color: "red",
          });
        });
    },
    editarFechaTermino() {
      this.loadingGuardarTerminoServicio = true;
      let datos = {
        id: this.datosEditar.id,
        fecha: this.fecha_termino,
      };
      EventServices.agregarFechaTermino(datos)
        .then((response) => {
          console.log("data", response);
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: "Fecha Actualizada",
            color: "success",
          });
          this.$emit("actualizar");
          this.loadingGuardarTerminoServicio = false;
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: error,
            color: "red",
          });
        });
    },
    cambiarHora() {
      console.log(this.horaFormateada);
      let datos = {
        id: this.datosEditar.id,
        hora: this.horaFormateada,
      };
      EventServices.cambiarHora(datos)
        .then((response) => {
          console.log("data", response);
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: "Hora Actualizada",
            color: "success",
          });
          this.$emit("actualizar");
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: error,
            color: "red",
          });
        });
    },
    datosGerenciamiento() {
      this.loading = true;
      EventServices.datosGerenciamiento(this.datosEditar.id)
        .then((response) => {
          console.log({ response });

          this.dataGerencia = response.data.datosGerenciamiento;
          this.datosOperador = response.data.datosOperador;
          this.viajeInicia = response.data.viajeInicia;
          this.ViajeRegreso = response.data.ViajeRegreso;
          this.datosVehiculo = response.data.datosVehiculo;
          this.folioActualizable =
            response.data.datosGerenciamiento.folio_servicio;

          this.economicoActualizable = {
            id: response.data.datosVehiculo.id,
            no_eco: response.data.datosVehiculo.no_eco,
          };

          this.kmActualizable = response.data.datosGerenciamiento.km_recorridos;
          this.txtObservaciones =
            response.data.datosGerenciamiento.observaciones;
          this.$emit("actualizar");
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: error,
            color: "red",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    editarFolio: function () {
      this.loadingGuardarFolio = true;
      let datos = {
        id: this.datosEditar.id,
        folio: this.folioActualizable,
      };
      EventServices.editarFolio(datos)
        .then((response) => {
          console.log("data", response);
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: "Folio Actualizado",
            color: "success",
          });
          this.$emit("actualizar");
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: error,
            color: "red",
          });
        })
        .finally(() => {
          this.loadingGuardarFolio = false;
        });
    },
    editarNoEco: function () {
      let datos = {
        id: this.dataGerencia.id,
        economico: this.economicoActualizable,
      };
      EventServices.editarEco(datos)
        .then((response) => {
          console.log("data", response);
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: "Vehiculo Actualizado",
            color: "success",
          });
          this.$emit("actualizar");
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: error,
            color: "red",
          });
        })
    },

    editarKM: function () {
      this.loadingGuardarKM = true;
      let datos = {
        id: this.dataGerencia.id,
        kmNuevo: this.kmActualizable,
      };
      EventServices.editarKm(datos)
        .then((response) => {
          console.log("data", response);
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: "KM Actualizado",
            color: "success",
          });
          this.$emit("actualizar");
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: error,
            color: "red",
          });
        })
        .finally(() => {
          this.loadingGuardarKM = false;
        });
    },

    editarObservaciones: function () {
      this.loadingObservaciones = true;
      let datos = {
        id: this.dataGerencia.id,
        observacion: this.txtObservaciones,
      };
      EventServices.editarObservaciones(datos)
        .then((response) => {
          console.log("data o", response);
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: "observacion actualizada",
            color: "success",
          });
          this.$emit("actualizar");
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: error,
            color: "red",
          });
        })
        .finally(() => {
          this.loadingObservaciones = false;
        });
    },

    cerrarGerenciamientoModal: function (resCompDialog) {
      this.$emit("actualizar", resCompDialog);
      this.$emit("cerrarSheetInfo", resCompDialog);
    },
    quitarCheckpoint: function (item, tipo_viaje) {
      if (tipo_viaje === "Inicial") {
        this.rutaEliminar = "gerenciamiento/quitarCheckpointVI/";
      } else {
        this.rutaEliminar = "gerenciamiento/quitarCheckpointVR/";
      }
      this.idCheckpoint = item.id;
      this.stateDialogEliminar = true;
    },
    cerrarModal: function (resCompDialog) {
      this.stateDialogEliminar = resCompDialog;
      this.stateDialogChangeRoute = resCompDialog;
      this.stateDialogBitacora = resCompDialog;
      this.stateShowCheck = resCompDialog;
      this.stateDialogConfirmar = resCompDialog;
      this.stateDialogEviCheck = resCompDialog;
      this.stateDialoMO = resCompDialog;
      this.stateDialogAdminServicio = resCompDialog;
    },

    async verPDF() {
      this.loadingButtonVer = true;
      await EventServices.pdf(this.dataGerencia.id)
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.click();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingButtonVer = false));
    },
  },
  watch: {
    stateSheetInfo(val) {
      if (val) {
        this.datosGerenciamiento();
        this.obtenerListasSelect();
        if (this.tipoSheet === "Dashboard") {
          let fecha = this.datosEditar.fecha_hora;
          var nuevaFecha = fecha.split(" ")[0].split("/").reverse().join("-");
          var nuevaHora = fecha.split(" ")[1];
          this.fechaFormateada = nuevaFecha;
          this.horaFormateada = nuevaHora;
          let fecha_termino = this.datosEditar.fecha_termino_servicio;
          this.fecha_termino = fecha_termino;
        } else {
          let fecha = this.datosEditar.fecha;
          var nueva = fecha.split(" ")[0].split("/").reverse().join("-");
          this.fechaFormateada = nueva;
          this.horaFormateada = this.datosEditar.hora_salida;
          let fecha_termino = this.datosEditar.fecha_termino_servicio;
          this.fecha_termino = fecha_termino;
        }
      }
    },
  },
};
</script>

<style>
.cardScroll {
  display: flex !important;
  flex-direction: column;
}
.cardSection {
  flex-grow: 1;
  overflow: auto;
}
</style>
