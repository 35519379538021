<template>
  <div class="text-center">
    <v-dialog
      @keydown.esc="cerrarDialog"
      v-model="stateDialog"
      width="450"
      @click:outside="cerrarDialog"
    >
      <v-card class="rounded-0">
        <v-card-title class="text-h6 grey lighten-2">
          Administrar Gerenciamiento
        </v-card-title>
        <div class="ma-5">
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header>Cambiar Estatus</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="datosEditar.estatus === 2">
                  <v-btn @click="cambiarEstatus" block color="success"
                    >Abrir Gerenciamiento</v-btn
                  >
                </div>
                <div class="text-center">
                  <h4>
                    Gerenciamiento {{ datosEditar.estatus === 1 ? "Abierto" : "Cerrado" }}
                  </h4>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text type="submit" :loading="loadingBoton">
            Guardar Bitacora
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventServices from "@/js/EventServices.js";
import { mapMutations } from "vuex";
import Mensajes from "@/js/Mensajes.js";

export default {
  name: "DialogAdminServicio",
  props: {
    stateDialog: Boolean,
    datosEditar: Object,
    tipo: String,
  },
  data: () => ({
    msgurl: "",
    loadingBoton: false,
  }),
  methods: {
    ...mapMutations(["SNACKBAR_MUTATION_MOSTRAR"]),

    cerrarDialog: function () {
      this.$emit("cerrarDialog", false);
      this.limpiarMensajesDeError();
    },

    limpiarMensajesDeError: function () {
      this.msgurl = "";
    },
    cambiarEstatus: function () {
      EventServices.cambiarEstatusServicio(this.datosEditar.id)
        .then((response) => {
          console.log(response);
          switch (response.data.mensaje) {
            case "guardado":
              this.cerrarDialog();
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;
          }
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({ titulo: error, color: "red" });
        });
    },
  },
};
</script>
