import store from "@/store";
export default {
    methods: {
        autorizar: function (permiso) {
            let menu = store.state.submenu;
            let id = store.state.idsubmenu;
            const data = menu.filter((e) => e.id === id);
            let res = data[0].permisos.some((p) => p === permiso)
            return res;
        }
    }
}

