<template>
  <div>
    <v-dialog
      @keydown.esc="cerrarDialog"
      v-model="stateDialog"
      width="450"
      @click:outside="cerrarDialog"
    >
      <v-card class="rounded-0 text-center">
        <form id="guardarDatos" @submit.prevent="eliminarRegistro">
          <v-card-title class="text-h6 lighten-2">
            ¿Realmente Desea Cerrar la Sesión?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="cerrarDialog" :loading="loading">
              Cancelar
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="aceptarCerrarSesion"
              :loading="loading"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "DialogCerrarSesion",
  props: {
    stateDialog: Boolean,
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapMutations(["CLOSE_LOGIN_MUTATION"]),
    cerrarDialog: function () {
      this.$emit("cerrarModal", false);
    },
    aceptarCerrarSesion: function () {
      localStorage.removeItem('userData');
      this.CLOSE_LOGIN_MUTATION();
      this.$router.push("/");
    },
  },
};
</script>
