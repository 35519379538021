<template>
  <div>
    <v-dialog
      @keydown.esc="cerrarDialog"
      v-model="stateDialogEliminar"
      width="30vw"
      @click:outside="cerrarDialog"
    >
      <v-card outlined class="rounded-0 text-center">
        <form id="guardarDatos" @submit.prevent="eliminarRegistro">
          <v-card-title v-if="tipo === 'eliminar'" class="text-h6 lighten-2">
            ¿Desea eliminar el siguiente registro?
          </v-card-title>

          <v-card-title v-if="tipo === 'baja'" class="text-h6 lighten-2">
            ¿Desea dar de baja el siguiente registro?
          </v-card-title>

          <v-card-title v-if="tipo === 'cancelar'" class="text-h6 lighten-2">
            ¿Desea cancelar el siguiente registro?
          </v-card-title>
          <v-card-actions class="text-h6">
            <v-spacer></v-spacer>
            <div v-if="autorizar('Eliminar')">
              <v-btn
                v-if="tipo === 'baja'"
                color="red darken-1"
                text
                type="submit"
                :loading="loading"
              >
                <v-icon> mdi-window-close </v-icon> Dar de Baja
              </v-btn>
            </div>
            <div v-if="autorizar('Eliminar')">
              <v-btn
                v-if="tipo === 'eliminar'"
                color="red darken-1"
                text
                type="submit"
                :loading="loading"
              >
                <v-icon> mdi-delete </v-icon> Eliminar
              </v-btn>
            </div>
            <div v-if="autorizar('Cancelar')">
              <v-btn
                v-if="tipo === 'cancelar'"
                color="red darken-1"
                text
                type="submit"
                :loading="loading"
              >
                <v-icon> mdi-cancel </v-icon> Cancelar
              </v-btn>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventServices from "@/js/EventServices.js";
import Mensajes from "@/js/Mensajes.js";
import { mapMutations } from "vuex";
import Mixin from "@/js/Mixin.js";
export default {
  mixins: [Mixin],
  name: "DialogEliminarRegistros",

  props: {
    tipo: String,
    stateDialogEliminar: Boolean,
    id: Number,
    url: String,
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    ...mapMutations(["SNACKBAR_MUTATION_MOSTRAR"]),

    cerrarDialog: function () {
      this.$emit("cerrarModalEliminar", false);
    },

    eliminarRegistro: function () {
      this.loading = true;
      EventServices.baja(this.url, this.id)
        .then((response) => {
          console.log(response);
          switch (response.data.mensaje) {
            case "EnServicio":
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.operadorEnServicio,
                color: "red",
              });
              this.$emit("actualizar");
              break;
            case "baja":
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.baja,
                color: "green",
              });
              this.$emit("actualizar");
              this.$emit("cerrarModalEliminar");
              break;
            case "eliminado":
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.delete,
                color: "green",
              });
              this.$emit("actualizar");
              break;
            case "cancelado":
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.cancelar,
                color: "green",
              });
              this.$emit("actualizar");
              break;
          }
        })
        .catch(() => {
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: Mensajes.error,
            color: "red",
          });
        })
        .finally(() => {
          this.cerrarDialog();
          this.loading = false;
        });
    },
  },
};
</script>
