var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"inspire"}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-overlay',{staticClass:"bg2",attrs:{"value":_vm.loading}},[_c('v-progress-circular',{staticClass:"gmglogo",staticStyle:{"color":"#f9bb00"},attrs:{"indeterminate":"","size":"200"}})],1)],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-app-bar',{staticClass:"amarillo",attrs:{"app":!_vm.loading,"dark":"","clipped-left":_vm.$vuetify.breakpoint.lgAndUp}},[_c('div',{staticClass:"mr-5"},[_c('v-img',{attrs:{"src":require("@/assets/GMGL.png"),"width":"40px"}})],1),(!_vm.isMobile)?_c('v-toolbar-title',{staticClass:"black--text font-weight-bold",staticStyle:{"font-size":"20px"}},[_vm._v("SEAL GMG")]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-large":"","plain":""},on:{"click":_vm.cerrarSesion}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30px","large":_vm.isMobile,"color":"black"}},[_vm._v(" mdi-logout ")])],1)]}}])},[_c('span',[_vm._v("Cerrar Sesión")])])],1),_c('v-main',[_c('v-container',{staticClass:"bg",attrs:{"fluid":""}},[_c('v-row',[_vm._l((_vm.menu),function(seccion){return _c('v-col',{key:seccion.id,attrs:{"lg":"3","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"elevation":seccion.autorizado ? (hover ? 20 : 0) : 0,"max-width":"100%"},on:{"click":function($event){return _vm.entrarAplicacion(seccion)}}},[_c('v-img',{class:seccion.autorizado
                      ? hover
                        ? 'black--text'
                        : 'white--text'
                      : 'white--text',attrs:{"height":"160px","src":_vm.exportarImagen(seccion.imagen)}},[_c('div',{staticStyle:{"height":"160px","background-color":"rgba(0, 0, 0, 0.5)"},style:(seccion.autorizado
                        ? hover
                          ? 'background-color: rgba(255, 255, 255, 0.30);cursor: pointer'
                          : 0
                        : 'background-color: rgba(215, 0, 0, 0.4);cursor: pointer')},[_c('div',{style:(seccion.text.length < 10
                          ? 'font-size: 45px; padding-top: 40px'
                          : seccion.text.length > 15
                          ? 'font-size: 40px; padding-top: 25px'
                          : 'font-size: 40px; padding-top: 45px')},[_vm._v(" "+_vm._s(seccion.text)+" ")])])])],1)]}}],null,true)})],1)}),_c('v-col',{attrs:{"lg":"3","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"elevation":hover ? 20 : 0,"max-width":"100%"},on:{"click":function($event){return _vm.abrirControlCambios()}}},[_c('v-img',{class:hover ? 'black--text' : 'white--text',attrs:{"height":"160px","src":_vm.exportarImagen('controlversiones')}},[_c('div',{staticStyle:{"height":"160px","background-color":"rgba(0, 0, 0, 0.397)"},style:(hover
                        ? 'background-color:  rgba(255, 255, 255, 0.30);cursor: pointer'
                        : 0)},[_c('div',{staticStyle:{"font-size":"35px","padding-top":"50px"}},[_vm._v(" Control de Cambios ")])])])],1)]}}])})],1)],2)],1),_c('DialogCerrarSesion',{attrs:{"stateDialog":_vm.stateDialogCerrarSesion},on:{"cerrarModal":_vm.cerrarDialog}}),_c('ControlCambios',{attrs:{"stateDialogControlCambios":_vm.stateDialogControlCambios},on:{"cerrarModal":_vm.cerrarDialog}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }