import axios from "axios";
import store from "@/store";

//let ip = "http://localhost/api-sealv1/";
let ip = "https://apigmg.gruasmoviles.com.mx/apigerencia/";

function API() {
  return axios.create({
    baseURL: ip,
    headers: {
      Authorization: `Bearer ${store.state.tokenState}`,
      roluser: [store.state.datosUsuariosState.rol, store.state.idsubmenu],
    },
  });
}

export default {
  baja(url, id) {
    //DAR DE BAJA registros
    return API().post(url + `${id}`);
  },
  //DAR DE ALTA REGISTROS
  alta(url, id) {
    return API().post(url + `${id}`);
  },
  cambiarEstatus(url, form) {
    return API().post(url, form);
  },
  //INICIAR SESIÓN
  IniciarSesion(datos) {
    return API().post("gerenciamiento/loginSession", datos);
  },
  // GERECIAMIENTO
  cambiarRutas(datos) {
    return API().post("gerenciamiento/cambiarRutas", datos);
  },
  obtenerDatosSelect() {
    return API().post("gerenciamiento/obtenerDatosSelect");
  },
  datosSelectores() {
    return API().get("gerenciamiento/datosSelectores");
  },
  datosGerenciamiento(id) {
    return API().post(`gerenciamiento/datosGerenciamiento/${id}`);
  },
  guardarGerenciamiento(form) {
    return API().post("gerenciamiento/nuevoGerenciamiento", form);
  },
  optenerGerenciamiento(datos) {
    return API().post("gerenciamiento/todoGerenciamiento", datos);
  },
  actualizarTiempoExtra(datos) {
    return API().post("gerenciamiento/actualizarTiempoExtra", datos);
  },
  agregarFechaTermino(datos) {
    return API().post("gerenciamiento/actualizarFechaTermino", datos);
  },
  actualizarTiempoMuerto(datos) {
    return API().post("gerenciamiento/actualizarTiempoMuerto", datos);
  },
  actualizarTiempoDescanso(datos) {
    return API().post("gerenciamiento/actualizarTiempoDescanso", datos);
  },
  actualizarFechaSalida(datos) {
    return API().post("gerenciamiento/actualizarFechaSalida", datos);
  },
  actualizarFechaRSalida(datos) {
    return API().post("gerenciamiento/actualizarFechaRSalida", datos);
  },
  actualizarFechaRLlegada(datos) {
    return API().post("gerenciamiento/actualizarFechaRLlegada", datos);
  },
  terminarGerenciamiento(datos) {
    return API().post(`gerenciamiento/terminarGerenciamiento`, datos);
  },
  cancelarGerenciamiento(id) {
    return API().post(`gerenciamiento/cancelarGerenciamiento/${id}`);
  },
  solicitudApertura(id) {
    return API().post(`gerenciamiento/solicitarApertura/${id}`);
  },
  DescargaPDF(folio) {
    return API().get(`gerenciamiento/verPDF/${folio}`, {
      responseType: "blob",
    });
  },
  pdf(id) {
    return API().get(`gerenciamiento/verPDF/${id}`, {
      responseType: "blob",
    });
  },
  guardarBitacora(datos) {
    return API().post("gerenciamiento/guardarBitacora", datos);
  },
  editarFolio(datos) {
    return API().post("gerenciamiento/editarFolio", datos);
  },
  editarKm(datos) {
    return API().post("gerenciamiento/editarKm", datos);
  },

 

  //DATOS SELECT
  listaSelectCientes() {
    return API().get("gerenciamiento/listaSelectClientes");
  },
  listaSelectOperador() {
    return API().get("gerenciamiento/listaSelectOperador");
  },
  
  
  listaSelectVehiculos() {
    return API().get("gerenciamiento/listaSelectVehiculos");
  },
  editarEco(datos) {
    return API().post("gerenciamiento/editarEconomico", datos);
  },

  
  
  cambiarFecha(datos) {
    return API().post("gerenciamiento/cambiarFecha", datos);
  },
  cambiarHora(datos) {
    return API().post("gerenciamiento/cambiarHora", datos);
  },

  editarObservaciones(datos) {
    return API().post("gerenciamiento/editarObservaciones", datos);
  },

  cambiarEstatusServicio(id) {
    return API().post(`gerenciamiento/cambiarEstatusServicio/${id}`);
  },
  //NOTIFICACIONES
  buscarGerenciamientos() {
    return API().get("notificaciones/buscarGerenciamientos");
  },
  listarNotificaciones() {
    return API().get("notificaciones/listarNotificaciones");
  },
  marcarLeido(id) {
    return API().post(`notificaciones/marcarLeido/${id}`);
  },

  listarDashboard(datos) {
    return API().post("dashboard/listarDashboard", datos);
  },

  //OPERADORES
  listarOperadores(datos) {
    return API().post("operadores/listarOperadores", datos);
  },
  listarDocumentacionOperador(id) {
    return API().get(`operadores/listarDocumentacionOperador/${id}`);
  },
  graficaOperadores(datos) {
    return API().post("operadores/graficaOperadores", datos);
  },
  listarOperadoresSinAsignar(datos) {
    return API().get("operadores/listarOperadoresSinAsignar", datos);
  },
  exportarRegistros(datos) {
    return API().post("operadores/exportarRegistros", datos);
  },
  datosOperador(id) {
    return API().get(`operadores/datosOperador/${id}`);
  },
  guardarOperador(datos) {
    return API().post("operadores/guardarOperador", datos);
  },
  editarOperador(datos) {
    return API().post("operadores/editarOperador", datos);
  },

  //USUARIOS
  listarusuariosRol(rol) {
    return API().get(`usuarios/listarusuariosRol/${rol}`);
  },
  listarUsuarios(datos) {
    return API().post("usuarios/listarUsuarios", datos);
  },
  datosUsuario(id) {
    return API().get(`usuarios/datosUsuario/${id}`);
  },
  guardarUsuario(datos) {
    return API().post("usuarios/guardarUsuario", datos);
  },
  editarUsuario(datos) {
    return API().post("usuarios/editarUsuario", datos);
  },

  //ROLES
  obtenerListarRoles() {
    return API().get(`usuarios/obtenerListarRoles`);
  },
  obtenerListaRutas(id) {
    return API().get(`usuarios/obtenerListaRutas/${id}`);
  },
  guardarPermisosRol(FormData) {
    return API().post(`usuarios/guardarPermisosRol`, FormData);
  },
  obtenerAplicaciones(rol) {
    return API().get(`usuarios/obtenerAplicaciones/${rol}`);
  },
  obtenerChildrensRutas(FormData) {
    return API().post(`usuarios/obtenerChildrensRutas`, FormData);
  },
  obtenerPermisos(FormData) {
    return API().put(`usuarios/obtenerPermisos`, FormData);
  },

  //RUTAS
  listarRutas(datos) {
    return API().post("rutas/listarRutas", datos);
  },
  listarRutasBases(datos) {
    return API().post("rutas/listarRutasBases", datos);
  },
  guardarRuta(datos) {
    return API().post("rutas/guardarRuta", datos);
  },
  guardarBaseRuta(datos) {
    return API().post("rutas/guardarBaseRuta", datos);
  },
  editarRuta(datos) {
    return API().post("rutas/editarRuta", datos);
  },
  reOrdenar(datos) {
    return API().post("rutas/reOrdenar", datos);
  },

  guardarCheckpoint(datos) {
    return API().post("rutas/guardarCheckpoint", datos);
  },
  editarCheckpoint(datos) {
    return API().post("rutas/editarCheckpoint", datos);
  },
  listarCheckpoints(id) {
    return API().post(`rutas/listarCheckpoints/${id}`);
  },

  //VEHICULOS
  listarTiposRemolques() {
    return API().get(`vehiculos/listarTiposRemolques`);
  },
  listarDocumentacionVehiculo(id) {
    return API().get(`vehiculos/listarDocumentacionVehiculo/${id}`);
  },
  listarVehiculos(datos) {
    return API().post("vehiculos/listarVehiculos", datos);
  },
  guardarVehiculo(datos) {
    return API().post("vehiculos/guardarVehiculo", datos);
  },
  editarVehiculo(datos) {
    return API().post("vehiculos/editarVehiculo", datos);
  },
  obtenerDataAsignacion(datos) {
    return API().put("vehiculos/obtenerDataAsignacion", datos);
  },
  asignarOperador(datos) {
    return API().post("vehiculos/asignarOperador", datos);
  },
  modificarAsignacion(datos) {
    return API().post("vehiculos/modificarAsignacion", datos);
  },
  eliminarAsignacion(datos) {
    return API().put("vehiculos/eliminarAsignacion", datos);
  },
  listaTiposVehiculos() {
    return API().get("vehiculos/listaTiposVehiculos");
  },
  guardarTipoVehiculo(data) {
    return API().post("vehiculos/guardarTipoVehiculo", data);
  },
  eliminarTipoVehiculo(id) {
    return API().post(`vehiculos/eliminarTipoVehiculo/${id}`);
  },
  listaHistoricoPlacas(id) {
    return API().get(`vehiculos/listaHistoricoPlacas/${id}`);
  },
  guardarRegistroPlaca(datos) {
    return API().put("vehiculos/guardarPlaca", datos);
  },
  alertarVencimientoPlaca() {
    return API().get(`vehiculos/alertarVencimientoPlaca`);
  },
  generarReporteLogistica(data) {
    return API().put(`vehiculos/generarReporteLogistica`, data, {
      responseType: "blob",
    });
  },
  VehiculoTipoUnidad(datos) {
    return API().put(`vehiculos/VehiculoTipoUnidad`, datos);
  },
  asignarUnidadRemolque(datos) {
    return API().put(`vehiculos/asignarUnidadRemolque`, datos);
  },
  //LOCALES
  listarLocales(datos) {
    return API().post("locales/listarLocales", datos);
  },
  agregarLocal(datos) {
    return API().post("locales/agregarLocal", datos);
  },
  actualizarLocal(datos) {
    return API().put("locales/actualizarLocal", datos);
  },
  llegadaBaseCliente(datos) {
    return API().post("locales/llegadaBaseCliente", datos);
  },
  salidaBaseGMG(datos) {
    return API().post("locales/salidaBaseGMG", datos);
  },
  salidaBaseClienteCargado(datos) {
    return API().post("locales/salidaBaseClienteCargado", datos);
  },
  llegadaLugarDescarga(datos) {
    return API().post("locales/llegadaLugarDescarga", datos);
  },
  finalizacionServicio(datos) {
    return API().post("locales/finalizacionServicio", datos);
  },
  llegadaBaseGMG(datos) {
    return API().post("locales/llegadaBaseGMG", datos);
  },
  cancelarLocal(datos) {
    return API().post("locales/cancelarLocal", datos);
  },
  finalizarLocal(datos) {
    return API().post("locales/finalizarLocal", datos);
  },

  listaClientes() {
    return API().post("clientes/listaClientes");
  },
  guardarCliente(datos) {
    return API().post("clientes/guardarCliente", datos);
  },
  editarCliente(datos) {
    return API().post("clientes/editarCliente", datos);
  },
  eliminarCliente(id) {
    return API().post(`clientes/eliminarCliente/${id}`);
  },

  listaContactos() {
    return API().get("contactos/listaContactos");
  },
  guardarContacto(datos) {
    return API().post("contactos/guardarContacto", datos);
  },
  eliminarContacto(id) {
    return API().post(`contactos/eliminarContacto/${id}`);
  },
  datosContacto(id) {
    return API().get(`contactos/datosContacto/${id}`);
  },

  listarEvidenciasCheck(id) {
    return API().post(`evidenciasCheck/listarEvidencias/${id}`);
  },
  guardarEvidenciaCheck(datos) {
    return API().post("evidenciasCheck/guardarEvidencia", datos);
  },
  eliminarEvidenciaCheck(id) {
    return API().post(`evidenciasCheck/eliminarEvidencia/${id}`);
  },

  buscarEvidencias(idGerencia) {
    return API().post(
      `evidenciasCheck/buscarEvidenciasMalaOperacion/${idGerencia}`
    );
  },
  guardarEvidenciaMalaOperacion(datos) {
    return API().put("evidenciasCheck/guardarEvidenciaMalaOperacion", datos);
  },

  eliminarEvidenciaMalaOperacion(id) {
    return API().delete(`evidenciasCheck/eliminarEvidenciaMalaOperacion/${id}`);
  },

  datosDelReporte() {
    return API().get("reporte/datosDelReporte");
  },
  pdfGeneral(data) {
    return API().put("reporte/pdf", data, {
      responseType: "blob",
    });
  },

  PdfFichaTecnica(data) {
    return API().put("reporte/pdfReporteFichaTecnica", data, {
      responseType: "blob",
    });
  },
  pdfOtrosReporte(data) {
    return API().put("reporte/pdfOtrosReporte", data, {
      responseType: "blob",
    });
  },

  datosDelReporteLocal() {
    return API().get("reportesLocales/datosDelReporte");
  },
  pdfGeneralLocal(data) {
    return API().put("reportesLocales/pdf", data, {
      responseType: "blob",
    });
  },
  pdfOtrosReporteLocal(data) {
    return API().put("reportesLocales/pdfOtrosReporte", data, {
      responseType: "blob",
    });
  },
  FichaTecnicaPDF(id_ficha) {
    return API().get(`fichaTecnica/FichaTecnicaPDF/${id_ficha}`, {
      responseType: "blob",
    });
  },
  solicitarApertura(ficha) {
    return API().get(`fichaTecnica/solicitarApertura/${ficha}`);
  },
  obtenerFichaTecnica(ficha) {
    return API().get(`fichaTecnica/obtenerFichaTecnica/${ficha}`);
  },
  listarModalidadTipoEquipo() {
    return API().get("fichaTecnica/listarModalidadTipoEquipo");
  },
  listarFichasTecnicas(datos) {
    return API().put("fichaTecnica/listarFichasTecnicas", datos);
  },
  listarInfoFichaTecnica(datos) {
    return API().put("fichaTecnica/listarInfoFichaTecnica", datos);
  },
  guardarFichaTecnica(datos) {
    return API().post("fichaTecnica/guardarFichaTecnica", datos);
  },
  modificarFichaTecnica(datos) {
    return API().post("fichaTecnica/modificarFichaTecnica", datos);
  },
  actualizarFolio(datos) {
    return API().put("fichaTecnica/actualizarFolio", datos);
  },

  descargaMasivaFichas(datos) {
    return API().put("fichaTecnica/descargaMasivaFichas", datos, {
      responseType: "blob",
    });
  },

  descargaMasivaGerenciamientos(datos) {
    return API().put("gerenciamiento/descargaMasivaGerencias", datos, {
      responseType: "blob",
    });
  },

  listarPublicaciones(datos) {
    return API().post("consultasPublicas/listarPublicaciones", datos);
  },
  obtenerDatosPublicacion(id) {
    return API().get(`consultasPublicas/obtenerDatosPublicacion/${id}`);
  },
  modificarFichaTecnicaLogistica(datos) {
    return API().post(
      "consultasPublicas/modificarFichaTecnicaLogistica",
      datos
    );
  },
  verificarFolio(datos) {
    return API().put(`consultasPublicas/verificarFolio`, datos);
  },
  consultarFichaTecnica(ficha) {
    return API().get(`consultasPublicas/consultarFichaTecnica/${ficha}`);
  },
  obtenerFichaPDFLogistica(ficha) {
    return API().get(`consultasPublicas/obtenerFichaPDFLogistica/${ficha}`, {
      responseType: "blob",
    });
  },

  guardarRegistro(datos) {
    return API().post("documentacion/guardarRegistro", datos);
  },
  listarRegistros(datos) {
    return API().put("documentacion/listarRegistros", datos);
  },
  guardarDocumentacion(datos) {
    return API().put("documentacion/guardarDocumentacion", datos);
  },
  modificarDocumentacion(datos) {
    return API().post("documentacion/modificarDocumentacion", datos);
  },
  listarTiposDocumentos() {
    return API().get("documentacion/listarTipoDocumentos");
  },
  listarHistorialDocumentacion(id) {
    return API().get(`documentacion/listarHistorialDocumentacion/${id}`);
  },
  guardarArchivo(datos) {
    return API().post("documentacion/guardarArchivo", datos);
  },

  listaCotizaciones(datos) {
    return API().put("cotizaciones/listaCotizaciones", datos);
  },

  guardarPublicacion(datos) {
    return API().post("publicaciones/guardarPublicacion", datos);
  },

  listarComponentesAsignados(id) {
    return API().get(`mantenimiento/listarComponentesAsignados/${id}`);
  },
  guardarComponentes(datos) {
    return API().post("mantenimiento/guardarComponentes", datos);
  },
  copiarComponentes(datos) {
    return API().put("mantenimiento/copiarComponentes", datos);
  },
  listarTipoMantto() {
    return API().get(`mantenimiento/listarTipoMantto`);
  },
  asignarTipoMantto(datos) {
    return API().post("mantenimiento/asignarTipoMantto", datos);
  },
  listarProxManttos(datos) {
    return API().put(`mantenimiento/listarProxManttos`, datos);
  },
  solicitarMantenimiento(datos) {
    return API().put("mantenimiento/solicitarMantenimiento", datos);
  },
  cerrarMantenimiento(datos) {
    return API().put("mantenimiento/cerrarMantenimiento", datos);
  },
  obtenerProgramaMantto(datos) {
    return API().put(`mantenimiento/obtenerProgramaMantto/`, datos, {
      responseType: "blob",
    });
  },
  exportarFichasExcel(datos) {
    return API().put("fichaTecnica/exportarFichasExcel", datos, {
      responseType: "blob",
    });
  },


  //
  reporteFichasPorVendedor(datos) {
    return API().put("reporte/reporteFichasPorVendedor", datos,{
      responseType: "blob",
    });
  },
};
