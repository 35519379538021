import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login/Login.vue";
import User from "@/views/General/User.vue";
import viewError from "@/views/General/404View.vue";
import NoAutorizado from "@/views/General/NoAutorizado.vue";
import Aplicaciones from "@/views/General/Aplicaciones.vue";
import Menu from "@/views/General/Menu.vue";

import store from "@/store";

Vue.use(VueRouter);
const routes = [
  {
    path: "*",
    component: viewError,
    name: "404",
  },
  {
    path: "/error",
    component: NoAutorizado,
    name: "error",
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/aplicaciones",
    name: "aplicaciones",
    component: Aplicaciones,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/menu",
    name: "menu",
    component: Menu,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "",
    name: "app",
    redirect: "/aplicaciones",
    component: User,
    meta: {
      requiresAuth: true,
    },
    children: [
      //ADMINISTRACION ---------------------------------
      {
        path: "roles",
        name: "roles",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Administracion/Roles.vue"
          ),
      },
      {
        path: "usuarios",
        name: "usuarios",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Administracion/Usuarios.vue"
          ),
      },
      {
        path: "publicaciones",
        name: "publicaciones",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Administracion/Publicaciones.vue"
          ),
      },
      {
        path: "aperturarFicha/:ficha",
        name: "aperturarFicha",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Administracion/AperturarFicha.vue"
          ),
      },
      //CMC ---------------------------------
      {
        path: "dashboard",
        name: "dashboard",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/CMC/Dashboard.vue"),
      },
      {
        path: "gerenciamientos",
        name: "gerenciamientos",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/CMC/Gerenciamientos.vue"
          ),
      },
      {
        path: "nuevoGerenciamiento",
        name: "nuevoGerenciamiento",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/CMC/NuevoGerenciamiento.vue"
          ),
      },
      {
        path: "locales",
        name: "locales",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/CMC/Locales.vue"),
      },
      {
        path: "rutas",
        name: "rutas",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/CMC/Rutas.vue"),
      },
      {
        path: "rutasbases",
        name: "rutasbases",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/CMC/RutasBases.vue"),
      },
      //LOGISTICA ---------------------------------
      {
        path: "operadores",
        name: "operadores",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Logistica/Operadores.vue"
          ),
      },
      {
        path: "vehiculos",
        name: "vehiculos",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Logistica/Vehiculos.vue"
          ),
      },
      {
        path: "documentosPermisos",
        name: "documentosPermisos",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Logistica/DocumentosPermisos.vue"
          ),
      },
      //VENTAS ---------------------------------
      {
        path: "fichaTecnica",
        name: "fichaTecnica",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Ventas/FichaTecnica.vue"
          ),
      },
      {
        path: "clientes",
        name: "clientes",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/Ventas/Clientes.vue"),
      },
      {
        path: "cotizaciones",
        name: "cotizaciones",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Ventas/Cotizaciones.vue"
          ),
      },
      //REPORTES ---------------------------------
      {
        path: "reportesForaneo",
        name: "reportesForaneo",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Reportes/ReportesForaneos.vue"
          ),
      },
      {
        path: "reportesLocales",
        name: "reportesLocales",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Reportes/ReportesLocales.vue"
          ),
      },
      {
        path: "reportesLogistica",
        name: "reportesLogistica",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Reportes/ReportesLogistica.vue"
          ),
      },
      {
        path: "reporteFichas",
        name: "reporteFichas",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Reportes/ReportesFichaTecnica.vue"
          ),
      },
      //MANTENIMIENTO ---------------------------------
      {
        path: "MantenimientosSolicitados",
        name: "MantenimientosSolicitados",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Mantenimiento/Mantenimiento.vue"
          ),
      },
      {
        path: "asignacionComponentes",
        name: "asignacionComponentes",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Mantenimiento/AsignacionComponentes.vue"
          ),
      },
      {
        path: "tipoMantto",
        name: "tipoMantto",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Mantenimiento/AsignarTipoMantto.vue"
          ),
      },
      {
        path: "proxmanttos",
        name: "proxmanttos",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Mantenimiento/ProximosMantenimientos.vue"
          ),
      },

      //GENERAL ---------------------------------
      {
        path: "notificaciones",
        name: "notificaciones",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/General/Notificaciones.vue"
          ),
      },
      {
        path: "factfichatecnica",
        name: "factfichatecnica",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/Facturacion/FacturacionFichaTecnica.vue"
          ),
      },

      // {
      //   path: "comisionesServicio",
      //   name: "comisionesServicio",
      //   component: () => import(/* webpackChunkName: "user" */ "@/views/ComisionServicio.vue"),
      // },
      // {
      //   path: "comisionesPeriodo",
      //   name: "comisionesPeriodo",
      //   component: () => import(/* webpackChunkName: "user" */ "@/views/ComisionesPeriodo.vue"),
      // },
      // {
      //   path: "asignarFicha",
      //   name: "asignarFicha",
      //   component: () => import(/* webpackChunkName: "user" */ "@/views/AsignacionesPedido.vue"),
      // },
      // {
      //   path: "facturacionFichaTecnica",
      //   name: "facturacionFichaTecnica",
      //   component: () => import(/* webpackChunkName: "user" */ "@/views/FacturacionFichaTecnica.vue"),
      // },
      // {
      //   path: "coordinacionFichaTecnica",
      //   name: "coordinacionFichaTecnica",
      //   component: () => import(/* webpackChunkName: "user" */ "@/views/CoordinacionFichaTecnica.vue"),
      // },
      // {
      //   path: "combustibleFichaTecnica",
      //   name: "combustibleFichaTecnica",
      //   component: () => import(/* webpackChunkName: "user" */ "@/views/CombustibleFichaTecnica.vue"),
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((router) => router.meta.requiresAuth)) {
    if (!store.state.loggedInState) {
      next("/");
    } else {
      var rutas = store.state.datosUsuariosState.rutas;
      if (rutas.includes(to.name)) {
        next();
      } else {
        next({
          path: "error",
        });
      }
    }
  } else {
    next();
  }
});

export default router;
