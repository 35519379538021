import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    // INICIO DE SESION
    loggedInState: false,
    tokenState: null,
    fotoState: null,
    datosUsuariosState: [],
    // COMPONENTES SNACKBAR
    snackBarState: false,
    snackBarTitulo: null,
    snackBarColor: null,
    // MENU ABIERTO O CERRADO
    menuState: true,
    data_gerenciamiento: {},
    id_app: [],
    submenu: [],
    idsubmenu: null,
  },

  mutations: {
    //LOGIN
    LOGGED_IN_MUTATION(state) {
      state.loggedInState = true;
    },
    TOKEN_MUTATION(state, token) {
      state.tokenState = token;
    },
    FOTO_MUTATION(state, foto) {
      state.fotoState = foto;
    },
    DATOS_USUARIOS_MUTATION(state, datos) {
      state.datosUsuariosState = datos;
    },

    CLOSE_LOGIN_MUTATION(state) {
      state.loggedInState = false;
      state.tokenState = null;
      state.fotoState = null;
      state.datosUsuariosState = [];
    },
    //COMPONENTES GLOBALES
    SNACKBAR_MUTATION_MOSTRAR(state, estadoNuevo) {
      state.snackBarTitulo = estadoNuevo.titulo;
      state.snackBarColor = estadoNuevo.color;
      state.snackBarState = true;
    },
    SNACKBAR_MUTATION_OCULTAR(state) {
      state.snackBarState = false;
    },
    MENU_MUTATION_MOSTRAR(state, estadoNuevo) {
      state.menuState = estadoNuevo;
    },
    DATOS_GERENCIAMIENTO(state, item) {
      state.data_gerenciamiento = item;
    },
    SETMENU(state, id_app) {
      state.id_app = id_app;
    },
    SETSUBMENU(state, submenu, id) {
      state.submenu = submenu;
      state.idsubmenu = id;
    },
    SETIDMENU(state, id) {
      state.idsubmenu = id;
    },
  },
  actions: {},
  modules: {},
});
