<template>
  <div>
    <v-container>
      <v-row align="center" justify="center" style="margin-top: 23%">
        <div class="headline">Error 404 | Pagina no encontrada</div>
      </v-row>
      <v-row align="center" justify="center" style="margin-top: 3%">
        <v-btn color="red" @click="regresar" outlined>Regresar</v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "404View",
  methods: {
    regresar() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
