<template>
<div>
    <div class="body"></div>
    <div class="grad"></div>
    <div class="header-main">
        <v-img class="headerimg" width="150" src="@/assets/GMGL.png"> </v-img>
        <div><span>SEAL</span>GMG</div>
        <div class="subtitulo">
            Sistema de Enlace
            <span>Administrativo y Logístico</span>
        </div>
    </div>
    <form v-on:submit.prevent="iniciarSesion">
        <v-card class="login" outlined>
            <span class="text-h6 white--text">Correo Electronico</span>
            <input type="text" class="mb-5" autofocus v-model="usuario" :error-messages="errorUsuario" />
            <span class="text-h6 white--text">Contraseña</span>
            <input class="mb-5" v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" label="Contraseña" :error-messages="errorPassword" />
            <v-btn block color="#f9bb00" type="submit"> Entrar </v-btn>
            <br />
            <v-card-actions class="textregister">
                ¿Necesitas Acceso?
                <v-spacer></v-spacer>
                <v-chip outlined color="#f9bb00"> Comunícate al área de TI</v-chip>
            </v-card-actions>
        </v-card>
    </form>
</div>
</template>

<script lang="js">
import {
    mapMutations
} from "vuex";
import EventServices from "@/js/EventServices.js";

export default {
    name: "LoginWeb",
    data: () => ({
        loading: false,
        disabled: false,
        show1: false,
        usuario: "",
        password: "",
        errorUsuario: "",
        errorPassword: "",
    }),
    mounted() {
        const datauser = localStorage.getItem('userData');
        if (datauser) {
          let dataparse = JSON.parse(datauser)
            console.log('dataparse', dataparse.data)
            this.DATOS_USUARIOS_MUTATION(dataparse.data);
            this.TOKEN_MUTATION(dataparse.token);
            this.FOTO_MUTATION(dataparse.foto);
            this.LOGGED_IN_MUTATION();
            this.$router.push("/aplicaciones");
        } else {
            this.CLOSE_LOGIN_MUTATION();
            this.$router.push("/");
        }
    },
    methods: {
        ...mapMutations([
            "SNACKBAR_MUTATION_MOSTRAR",
            "SNACKBAR_MUTATION_OCULTAR",
            "LOGGED_IN_MUTATION",
            "TOKEN_MUTATION",
            "CLOSE_LOGIN_MUTATION",
            "DATOS_USUARIOS_MUTATION",
            "FOTO_MUTATION",
        ]),
        async iniciarSesion() {
            this.loading = true;
            this.disabled = true;
            let datosLogin = {
                correo: this.usuario,
                password: this.password,
            };
            await EventServices.IniciarSesion(datosLogin)
                .then((response) => {

                    console.log("response", response);
                    switch (response.data.mensaje) {
                        case "todoBien":
                            console.log('response', response)
                            localStorage.setItem('userData', JSON.stringify(response.data));
                            this.DATOS_USUARIOS_MUTATION(response.data.data);
                            this.TOKEN_MUTATION(response.data.token);
                            this.FOTO_MUTATION(response.data.foto);
                            this.LOGGED_IN_MUTATION();
                            this.$router.push("/aplicaciones");
                            break;
                        case "errorDeValidacion":
                            this.errorUsuario = response.data.errores.correo;
                            this.errorPassword = response.data.errores.password;
                            break;
                        default:
                            this.CLOSE_LOGIN_MUTATION();
                            break;
                    }
                })
                .catch(function (error) {
                    this.SNACKBAR_MUTATION_MOSTRAR({
                        titulo: error,
                        color: "red"
                    });
                })
                .finally(() => {
                    this.loading = false;
                    this.disabled = false;
                });
        },
    },
};
</script>

<style scoped>
body {
    margin: 0;
    padding: 0;
    background: #fff;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
}

.body {
    position: absolute;
    top: -20px;
    left: -20px;
    right: -40px;
    bottom: -40px;
    width: auto;
    height: auto;
    background-image: url("../../assets/patioGmg.jpg");
    background-size: cover;
    -webkit-filter: blur(3px);
    z-index: 0;
}

.grad {
    position: absolute;
    top: -20px;
    left: -20px;
    right: -40px;
    bottom: -40px;
    width: auto;
    height: auto;
    background: -webkit-gradient(linear,
            left top,
            left bottom,
            color-stop(70%, rgba(0, 0, 0, 0.615)),
            color-stop(100%, rgb(255, 255, 255)));
    /* Chrome,Safari4+ */
    z-index: 1;
    opacity: 0.7;
}

.header-main {
    position: absolute;
    top: calc(40% - 35px);
    left: calc(10% - 35px);
    z-index: 2;
}

.header-main .headerimg {
    top: 60px;
    left: calc(0% - 35px);
}

.header-main .subtitulo {
    top: calc(100% - 20px);
    left: calc(30% - 35px);
    position: absolute;
    font-size: 40px;
    z-index: 2;
}

.header-main div {
    float: left;
    color: #fff;
    font-family: "Exo", sans-serif;
    font-size: 100px;
}

.header-main div span {
    color: #f9bb00 !important;
}

.textregister {
    color: #fff;
}

.login {
    border: 2px solid #f9bb00;
    border-radius: 15px;
    background-color: transparent;
    position: absolute;
    top: calc(42% - 90px);
    left: calc(70% - 70px);
    height: auto;
    width: 30vw;
    padding: 15px;
    z-index: 2;
}

.login input[type="text"] {
    width: 100%;
    height: 50px;
    background: transparent;
    border: 2px solid #f5b800;
    border-radius: 10px;
    color: #fff;
    font-family: "Exo", sans-serif;
    font-size: 20px;
    font-weight: 400;
    padding: 4px;
    margin-top: 10px;
}

.login input[type="password"] {
    width: 100%;
    height: 50px;
    background: transparent;
    border: 2px solid #f5b800;
    border-radius: 10px;
    color: #fff;
    font-family: "Exo", sans-serif;
    font-size: 20px;
    font-weight: 400;
    padding: 4px;
    margin-top: 10px;
}

.login input[type="text"]:focus {
    outline: none;
    border: 2px solid #f9bb00;
}

.login input[type="password"]:focus {
    outline: none;
    border: 2px solid #f9bb00;
}

::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
}

::-moz-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
}
</style>
