<template>
  <div class="text-center">
    <v-snackbar
      :color="snackBarColor"
      v-model="snackBarState"
      transition="scroll-y-transition"
      right
    >
      {{ snackBarTitulo }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="cerrarSnackbar">CERRAR</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="js">
import { mapState, mapMutations } from "vuex";
export default {
  name: "Snackbar",

  data: () => ({
    intervalo: null,
  }),
  computed: {
    ...mapState(["snackBarState", "snackBarTitulo", "snackBarColor"]),
  },
  methods: {
    ...mapMutations(["SNACKBAR_MUTATION_OCULTAR"]),
    cerrarSnackbar: function () {
      this.SNACKBAR_MUTATION_OCULTAR();
    },
  },

  watch: {
    snackBarState: function (valor) {
      if (valor) {
        this.intervalo = setInterval(() => {
          this.SNACKBAR_MUTATION_OCULTAR();
          clearInterval(this.intervalo);
        }, 1500);
      }
    },
  },
};
</script>
