<template>
  <v-app id="inspire">
    <div v-if="loading" class="text-center">
      <v-overlay class="bg2" :value="loading">
        <v-progress-circular
          style="color: #f9bb00"
          class="gmglogo"
          indeterminate
          size="200"
        >
        </v-progress-circular>
      </v-overlay>
    </div>
    <div class="text-center">
      <v-app-bar
        :app="!loading"
        dark
        class="amarillo"
        :clipped-left="$vuetify.breakpoint.lgAndUp"
      >
        <div class="mr-5">
          <v-img src="@/assets/GMGL.png" width="40px"></v-img>
        </div>
        <v-toolbar-title
          v-if="!isMobile"
          style="font-size: 20px"
          class="black--text font-weight-bold"
          >SEAL GMG</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" x-large plain @click="cerrarSesion">
              <v-icon size="30px" :large="isMobile" color="black">
                mdi-logout
              </v-icon>
            </v-btn>
          </template>
          <span>Cerrar Sesión</span>
        </v-tooltip>
      </v-app-bar>
      <v-main>
        <v-container class="bg" fluid>
          <v-row>
            <v-col v-for="seccion in menu" :key="seccion.id" lg="3" sm="6">
              <v-hover v-slot="{ hover }">
                <v-card
                  @click="entrarAplicacion(seccion)"
                  :elevation="seccion.autorizado ? (hover ? 20 : 0) : 0"
                  max-width="100%"
                >
                  <v-img
                    :class="
                      seccion.autorizado
                        ? hover
                          ? 'black--text'
                          : 'white--text'
                        : 'white--text'
                    "
                    height="160px"
                    :src="exportarImagen(seccion.imagen)"
                  >
                    <div
                      :style="
                        seccion.autorizado
                          ? hover
                            ? 'background-color: rgba(255, 255, 255, 0.30);cursor: pointer'
                            : 0
                          : 'background-color: rgba(215, 0, 0, 0.4);cursor: pointer'
                      "
                      style="
                        height: 160px;
                        background-color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      <div
                        :style="
                          seccion.text.length < 10
                            ? 'font-size: 45px; padding-top: 40px'
                            : seccion.text.length > 15
                            ? 'font-size: 40px; padding-top: 25px'
                            : 'font-size: 40px; padding-top: 45px'
                        "
                      >
                        {{ seccion.text }}
                      </div>
                    </div>
                  </v-img>
                </v-card>
              </v-hover>
            </v-col>
            <v-col lg="3" sm="6">
              <v-hover v-slot="{ hover }">
                <v-card
                  @click="abrirControlCambios()"
                  :elevation="hover ? 20 : 0"
                  max-width="100%"
                >
                  <v-img
                    :class="hover ? 'black--text' : 'white--text'"
                    height="160px"
                    :src="exportarImagen('controlversiones')"
                  >
                    <div
                      :style="
                        hover
                          ? 'background-color:  rgba(255, 255, 255, 0.30);cursor: pointer'
                          : 0
                      "
                      style="
                        height: 160px;
                        background-color: rgba(0, 0, 0, 0.397);
                      "
                    >
                      <div style="font-size: 35px; padding-top: 50px">
                        Control de Cambios
                      </div>
                    </div>
                  </v-img>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
        <DialogCerrarSesion
          :stateDialog="stateDialogCerrarSesion"
          @cerrarModal="cerrarDialog"
        />
        <ControlCambios
          :stateDialogControlCambios="stateDialogControlCambios"
          @cerrarModal="cerrarDialog"
        />
      </v-main>
    </div>
  </v-app>
</template>

<script lang="js">
import EventServices from "@/js/EventServices.js";
import DialogCerrarSesion from "@/components/DialogCerrarSesion";
import ControlCambios from "./ControlCambios.vue";
import Mixin from "@/js/Mixin.js";
import {
    mapMutations
} from "vuex";

import {
    estilosMenuApps
} from "@/js/Helpers.js";

export default {
    mixins: [Mixin],
    name: "Principal",
    components: {
        DialogCerrarSesion,
        ControlCambios
    },
    data: () => ({
        stateDialogControlCambios: false,
        isMobile: false,
        stateDialogCerrarSesion: false,
        loading: false,
        rol: null,
        menu: [],
    }),
    mounted() {
        this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        this.loading = true;
        this.buscarAplicaciones();
        this.rol = this.$store.state.datosUsuariosState.rol;
        console.log(this.$store.state.datosUsuariosState)
    },
    methods: {
        ...mapMutations(["SETMENU",
            "SETIDMENU", "SNACKBAR_MUTATION_MOSTRAR", "CLOSE_LOGIN_MUTATION"
        ]),
        estilosMenu(hover, seccion) {
            return estilosMenuApps(hover, seccion);
        },
        abrirControlCambios() {
            this.stateDialogControlCambios = true;
        },
        async buscarAplicaciones() {
            await EventServices.obtenerAplicaciones(this.$store.state.datosUsuariosState.rol).then((response) => {
                this.menu = response.data.datos_rutas;
            }).finally(() => {
                this.loading = false;
            });
        },
        async entrarAplicacion(item) {
            console.log('ruta', item)
            if (item.ruta === "") {
                if (item.autorizado) {
                    this.SETMENU(item.id);
                    this.$router.push("/menu");
                } else {
                    this.SNACKBAR_MUTATION_MOSTRAR({
                        titulo: "No tiene acceso a esta sección",
                        color: "red",
                    });
                }
            } else {
                this.$router.push(item.ruta);
                this.SETIDMENU(item.id);
            }
        },
        cerrarSesion: function () {
            this.stateDialogCerrarSesion = true;
        },
        cerrarDialog: function (props) {
            this.stateDialogCerrarSesion = props;
            this.stateDialogControlCambios = props;
        }
    },
};
</script>

<style scoped>
.bg2 {
  height: 95vh;
  margin-bottom: -100px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../assets/fondo-camion.png") no-repeat center center;
  background-size: cover;
  transform: scale(1.1);
}

.gmglogo {
  background: url("../../assets/GMGL.png") no-repeat center center;
  background-size: cover;
  transform: scale(1.1);
}

.notextelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bg {
  padding: 3%;
  padding-top: 30px;
  min-height: 95vh;
  background-image: url("../../assets/fondo-camion.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  color: #ffffff;
}
</style>
