<template>
  <div class="text-center">
    <v-dialog
      @keydown.esc="cerrarDialog"
      v-model="stateDialogChangeRoute"
      width="550"
      @click:outside="cerrarDialog"
    >
      <v-card v-if="selectViaje">
        <v-card-title class="text-h6 grey lighten-2"> Agregar Checkpoints </v-card-title>
        <div class="ma-5 text-center">
          <v-btn
            v-if="tipo_viaje === 'Inicial'"
            :disabled="datosEditar.tipo_check === 'Origen'"
            @click="anterior"
            color="success"
            tile
          >
            Anterior
            <v-icon right dark> mdi-arrow-up-bold-outline </v-icon>
          </v-btn>
          <v-btn
            v-else
            :disabled="datosEditar.tipo_check === 'Destino'"
            @click="anterior"
            color="success"
            tile
          >
            Anterior
            <v-icon right dark> mdi-arrow-up-bold-outline </v-icon>
          </v-btn>
          <v-btn
            v-if="tipo_viaje === 'Inicial'"
            :disabled="datosEditar.tipo_check === 'Destino'"
            @click="siguiente"
            class="ml-5"
            color="error"
            tile
          >
            <v-icon left dark> mdi-arrow-down-bold-outline </v-icon>
            Siguiente
          </v-btn>
          <v-btn
            v-else
            :disabled="datosEditar.tipo_check === 'Origen'"
            @click="siguiente"
            class="ml-5"
            color="error"
            tile
          >
            <v-icon left dark> mdi-arrow-down-bold-outline </v-icon>
            Siguiente
          </v-btn>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          Checkpoint seleccionado: {{ datosEditar.checkpoint }}</v-card-actions
        >
      </v-card>
      <v-card v-if="selectRuta" class="rounded-0">
        <v-card-title class="text-h6 grey lighten-2" v-if="posAnterior">
          Agregar Checkpoint antes de {{ datosEditar.checkpoint }}
        </v-card-title>
        <v-card-title class="text-h6 grey lighten-2" v-if="posSiguiente">
          Agregar Checkpoint despues de {{ datosEditar.checkpoint }}
        </v-card-title>
        <form ref="guardarDatos" @submit.prevent="cambiarRuta">
          <div class="ma-5">
            <input :value="datosEditar.id" hidden name="id" />
            <input v-if="tipo_viaje === 'Inicial'" value="Inicial" hidden name="viaje" />
            <input v-if="posAnterior" value="anterior" hidden name="direccion" />
            <input v-if="tipo_viaje === 'Regreso'" value="Regreso" hidden name="viaje" />
            <input v-if="posSiguiente" value="siguiente" hidden name="direccion" />
            <input :value="dataGerencia.id" hidden name="id_gerencia" />
            <input :value="datosEditar.posicion" hidden name="posicion" />
            <v-text-field
              label="CheckPoint"
              name="checkpoint"
              v-on:keypress="msgCheckpoint = ''"
              :error-messages="msgCheckpoint"
            >
            </v-text-field>
            <v-text-field
              type="number"
              step="any"
              label="Tiempo que recorre"
              name="tiempo_recorre"
              v-on:keypress="msgTiempoRecorre = ''"
              :error-messages="msgTiempoRecorre"
            >
            </v-text-field>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text type="submit" :loading="loadingBoton">
              Guardar Nueva Ruta
            </v-btn>
            <v-btn color="grey" text @click="cancelarCambio" :loading="loadingBoton">
              Cancelar
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventServices from "@/js/EventServices.js";
import Mensajes from "@/js/Mensajes.js";
import { mapMutations } from "vuex";

export default {
  name: "DialogChangeRoute",
  props: {
    stateDialogChangeRoute: Boolean,
    datosEditar: Object,
    dataGerencia: Object,
    tipo_viaje: String,
  },
  data: () => ({
    posSiguiente: false,
    posAnterior: false,
    selectViaje: true,
    selectRuta: false,
    loadingBoton: false,
    listaRutas: [],
    msgSltCliente: "",
    msgCheckpoint: "",
    msgTiempoRecorre: "",
  }),

  methods: {
    ...mapMutations(["SNACKBAR_MUTATION_MOSTRAR"]),

    cerrarDialog: function () {
      this.$emit("cerrarDialogBitacora", false);
      this.limpiarMensajesDeError();
    },

    limpiarMensajesDeError: function () {
      this.msgurl = "";
    },
    anterior: function () {
      this.selectViaje = false;
      this.selectRuta = true;
      this.posSiguiente = false;
      this.posAnterior = true;
    },
    siguiente: function () {
      this.selectViaje = false;
      this.selectRuta = true;
      this.posSiguiente = true;
      this.posAnterior = false;
    },
    cancelarCambio: function () {
      this.selectViaje = true;
      this.selectRuta = false;
    },
    cambiarRuta: function () {
      var formData = new FormData(this.$refs.guardarDatos);
      EventServices.cambiarRutas(formData).then((response) => {
        switch (response.data.mensaje) {
          case "guardado":
            this.cerrarDialog();
            this.$emit("actualizar");
            this.SNACKBAR_MUTATION_MOSTRAR({
              titulo: Mensajes.success,
              color: "success",
            });
            break;
          case "error":
            this.SNACKBAR_MUTATION_MOSTRAR({
              titulo: Mensajes.errorTerminarGerencia,
              color: "error",
            });
            break;
          default:
            this.SNACKBAR_MUTATION_MOSTRAR({
              titulo: Mensajes.error,
              color: "red",
            });
            break;
        }
      });
    },
  },
  watch: {
    stateDialogChangeRoute() {
      if (this.stateDialogChangeRoute === false) {
        this.selectViaje = true;
        this.selectRuta = false;
      }
    },
  },
};
</script>
