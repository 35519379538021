<template>
  <div class="text-center">
    <v-dialog
      @keydown.esc="cerrarDialog"
      v-model="stateDialogBitacora"
      width="450"
      @click:outside="cerrarDialog"
    >
      <v-card class="rounded-0">
        <v-card-title v-if="tipo === 'Agregar'" class="text-h6 grey lighten-2">
          Agregar PDF
        </v-card-title>
        <v-card-title v-else class="text-h6 grey lighten-2">
          Editar PDF
        </v-card-title>
        <form ref="guardarDatos" @submit.prevent="guardarBitacora">
          <div class="ma-5">
            <input :value="datosEditar.id" hidden name="id" />
            <v-text-field
              label="Bitacora"
              name="pdf_bitacora"
              v-on:keypress="msgurl = ''"
              :error-messages="msgurl"
            >
            </v-text-field>
          </div>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text type="submit" :loading="loadingBoton">
              Guardar Bitacora
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventServices from "@/js/EventServices.js";
import { mapMutations } from "vuex";
import Mensajes from "@/js/Mensajes.js";

export default {
  name: "DialogAddBitacora",

  props: {
    stateDialogBitacora: Boolean,
    datosEditar: Object,
    tipo: String,
  },

  data: () => ({
    msgurl: "",
    loadingBoton: false,
  }),

  methods: {
    ...mapMutations(["SNACKBAR_MUTATION_MOSTRAR"]),

    cerrarDialog: function () {
      this.$emit("cerrarDialogBitacora", false);
      this.limpiarMensajesDeError();
    },

    limpiarMensajesDeError: function () {
      this.msgurl = "";
    },

    guardarBitacora: function () {
    console.log(this.datosEditar);
      var formData = new FormData(this.$refs.guardarDatos);
      EventServices.guardarBitacora(formData)
        .then((response) => {
          console.log(response)
          switch (response.data.mensaje) {
            case "guardado":
              this.cerrarDialog();

              this.$emit("actualizar");
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;

            case "errorDeValidacion":
              this.msgurl = response.data.errores.pdf_bitacora;
              break;

            default:
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.error,
                color: "red",
              });
              break;
          }
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({ titulo: error, color: "red" });
        });
    },
  },

};
</script>