<template>
  <div class="text-center">
    <v-dialog
      @keydown.esc="cerrarDialog"
      v-model="stateShowCheck"
      width="500"
      @click:outside="cerrarDialog"
    >
      <v-card class="rounded-0">
        <v-card-title class="text-h6 grey lighten-2"
          >CheckPoint {{ datosShow.checkpoint }}</v-card-title
        >
        <form ref="datosCheck" @submit.prevent="submitCheck">
          <input name="id_gerencia" :value="datosShow.id_gerenciamiento" hidden />
          <input name="id_operador" :value="datosShow.id_operador" hidden />
          <input name="id_checkpoint" :value="datosShow.id_checkpoint" hidden />
          <input name="id" :value="datosShow.id" hidden />
          <input name="fecha_salida" :value="datosShow.fecha_salida" hidden />
          <input name="fecha_llegada" :value="datosShow.fecha_llegada" hidden />
          <input name="tipo_viaje" :value="tipoViaje" hidden />
          <input name="tipo_accion" :value="tipoAccion" hidden />
          <div class="ma-5">
            <v-textarea
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tiempo_descanso'"
              name="comentario_tiempo_descanso"
              label="Comentario Tiempo Descanso"
              v-model="datosShow.comentario_tiempo_descanso"
            ></v-textarea>
            <div class="row">
              <div class="col-6">
                <v-text-field
                  :disabled="!autorizar('Modificar')"
                  outlined
                  v-if="tipo === 'tiempo_descanso'"
                  type="date"
                  name="tiempo_descanso_fecha1"
                  label="Añadir Fecha Inicial"
                  v-model="datosShow.fechainicial_tiempo_descanso"
                  v-on:keypress="msgfechainicial_tiempo_descanso = ''"
                  :error-messages="msgfechainicial_tiempo_descanso"
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-text-field
                  :disabled="!autorizar('Modificar')"
                  outlined
                  v-if="tipo === 'tiempo_descanso'"
                  type="time"
                  name="tiempo_descanso_hora1"
                  label="Añadir Hora Inicial"
                  v-model="datosShow.horainicial_tiempo_descanso"
                  v-on:keypress="msghorainicial_tiempo_descanso = ''"
                  :error-messages="msghorainicial_tiempo_descanso"
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-text-field
                  :disabled="!autorizar('Modificar')"
                  outlined
                  v-if="tipo === 'tiempo_descanso'"
                  type="date"
                  name="tiempo_descanso_fecha2"
                  label="Añadir Fecha Final"
                  v-model="datosShow.fechafinal_tiempo_descanso"
                  v-on:keypress="msgfechafinal_tiempo_descanso = ''"
                  :error-messages="msgfechafinal_tiempo_descanso"
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-text-field
                  :disabled="!autorizar('Modificar')"
                  outlined
                  v-if="tipo === 'tiempo_descanso'"
                  type="time"
                  name="tiempo_descanso_hora2"
                  label="Añadir Hora Final"
                  v-model="datosShow.horafinal_tiempo_descanso"
                  v-on:keypress="msghorafinal_tiempo_descanso = ''"
                  :error-messages="msghorafinal_tiempo_descanso"
                ></v-text-field>
              </div>
            </div>

            <v-textarea
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tiempo_extra'"
              name="comentario_tiempo_extra"
              label="Comentario Tiempo Extra"
              v-model="datosShow.comentario_tiempo_extra"
              v-on:keypress="msgComentarioTiempoExtra = ''"
              :error-messages="msgComentarioTiempoExtra"
            ></v-textarea>

            <v-text-field
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tiempo_extra'"
              type="number"
              name="tiempo_extra"
              label="Añadir Tiempo Extra"
              v-model="datosShow.tiempo_extra"
              v-on:keypress="msgTiempoExtra = ''"
              :error-messages="msgTiempoExtra"
            ></v-text-field>

            <v-textarea
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tiempo_muerto'"
              name="comentario_tiempo_muerto"
              label="Comentario Tiempo Muerto"
              v-model="datosShow.comentario_tiempo_muerto"
              v-on:keypress="msgComentarioTiempoMuerto = ''"
              :error-messages="msgComentarioTiempoMuerto"
            ></v-textarea>

            <v-text-field
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tiempo_muerto'"
              type="number"
              name="tiempo_muerto"
              label="Añadir Tiempo Muerto"
              v-model="datosShow.tiempo_muerto"
              v-on:keypress="msgTiempoMuerto = ''"
              :error-messages="msgTiempoMuerto"
            ></v-text-field>

            <v-text-field
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'fecha_salida'"
              type="date"
              name="fecha_salida"
              label="Fecha de Salida"
              v-model="fecha"
              v-on:keypress="msgFechaSalida = ''"
              :error-messages="msgFechaSalida"
            ></v-text-field>

            <v-text-field
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'fecha_salida'"
              type="time"
              name="hora_salida"
              label="Hora de Salida"
              v-on:keypress="msgHoraSalida = ''"
              :error-messages="msgHoraSalida"
            ></v-text-field>
            <v-text-field
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tr_salida' && tipo_check != 'Destino'"
              type="text"
              name="verificacion"
              label="Verificación CMC"
              :value="datosShow.verificacion"
            ></v-text-field>
            <v-text-field
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tr_salida'"
              type="text"
              name="observaciones"
              label="Observaciones"
              :value="datosShow.observaciones"
            ></v-text-field>
            <v-text-field
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tr_salida' && tipo_check != 'Destino'"
              type="date"
              name="fr_salida"
              label="Fecha Real Salida"
              v-model="fecha"
              v-on:keypress="msgFechaRSalida = ''"
              :error-messages="msgFechaRSalida"
            ></v-text-field>
            <v-text-field
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tr_salida' && tipo_check != 'Destino'"
              type="time"
              name="tr_salida"
              label="Hora Real Salida"
              v-on:keypress="msgHoraRSalida = ''"
              :error-messages="msgHoraRSalida"
            ></v-text-field>
            <v-text-field
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tr_llegada'"
              type="date"
              name="fr_llegada"
              label="Fecha Real Llegada"
              v-model="fecha"
              v-on:keypress="msgFechaRLlegada = ''"
              :error-messages="msgFechaRLlegada"
            ></v-text-field>
            <v-text-field
              :disabled="!autorizar('Modificar')"
              outlined
              v-if="tipo === 'tr_llegada'"
              type="time"
              name="tr_llegada"
              label="Hora Real Llegada"
              v-on:keypress="msgHoraRLlegada = ''"
              :error-messages="msgHoraRLlegada"
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <div v-if="tipo === 'tiempo_extra' || tipo === 'tiempo_muerto'">
              Nota: Tiempo Extra y Tiempo Muerto en Minutos
            </div>
            <v-spacer></v-spacer>
            <v-btn v-if="autorizar('Modificar')" color="primary" text type="submit"
              >Aceptar</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EventServices from "@/js/EventServices.js";
import Mensajes from "@/js/Mensajes.js";
import { mapMutations } from "vuex";
import Mixin from "@/js/Mixin.js";
export default {
  mixins: [Mixin],
  name: "ShowCheck",
  props: {
    tipo_check: String,
    tipoViaje: String,
    titulo: String,
    stateShowCheck: Boolean,
    datosShow: Object,
    tipo: String,
    tipoAccion: String,
  },
  data: () => ({
    msgfechainicial_tiempo_descanso: "",
    msghorainicial_tiempo_descanso: "",
    msgfechafinal_tiempo_descanso: "",
    msghorafinal_tiempo_descanso: "",
    fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    msgComentarioTiempoMuerto: "",
    msgTiempoMuerto: "",
    msgComentarioTiempoExtra: "",
    msgTiempoRealLlegada: "",
    msgtiempo_ida: "",
    msgtiempo_regreso: "",
    msgTipo: "",
    msgTiempoExtra: "",
    msgFechaSalida: "",
    msgHoraSalida: "",
    msgFechaRSalida: "",
    msgHoraRSalida: "",
    msgFechaRLlegada: "",
    msgHoraRLlegada: "",
    loading: false,
    itemsTipo: [{ tipo: "Origen" }, { tipo: "Checkpoint" }, { tipo: "Destino" }],
    itemsTiempoExtra: [
      { name: "No" },
      { name: "Descanso" },
      { name: "descanso + Comida" },
    ],
  }),
  methods: {
    ...mapMutations(["SNACKBAR_MUTATION_MOSTRAR"]),
    cerrarDialog: function () {
      this.$emit("cerrarShowCheck", false);
    },

    submitCheck: function () {
      var formData = new FormData(this.$refs.datosCheck);
      this.loadingBoton = true;
      switch (this.tipo) {
        case "tiempo_extra":
          this.actualizarTiempoExtra(formData);
          break;

        case "tiempo_muerto":
          this.actualizarTiempoMuerto(formData);
          break;

        case "tiempo_descanso":
          this.actualizarTiempoDescanso(formData);
          break;

        case "fecha_salida":
          this.actualizarFechaSalida(formData);
          break;
        case "tr_salida":
          this.actualizarFechaRSalida(formData);
          break;
        case "tr_llegada":
          this.actualizarFechaRLlegada(formData);
          break;
        default:
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: Mensajes.error,
            color: "red",
          });
          break;
      }
      this.loadingBoton = false;
    },

    actualizarTiempoMuerto(formData) {
      EventServices.actualizarTiempoMuerto(formData)
        .then((response) => {
          console.log(response);
          switch (response.data.mensaje) {
            case "guardado":
              this.cerrarDialog();
              this.$emit("actualizar");
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;

            case "errorDeValidacion":
              this.msgTiempoMuerto = response.data.errores.tiempo_muerto;
              break;

            default:
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.error,
                color: "red",
              });
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    actualizarTiempoDescanso(formData) {
      EventServices.actualizarTiempoDescanso(formData)
        .then((response) => {
          console.log(response);
          switch (response.data.mensaje) {
            case "guardado":
              this.cerrarDialog();
              this.$emit("actualizar");
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;

            case "errorDeValidacion":
              this.msgfechainicial_tiempo_descanso =
                response.data.errores.tiempo_descanso_fecha1;
              this.msghorainicial_tiempo_descanso =
                response.data.errores.tiempo_descanso_hora1;
              this.msgfechafinal_tiempo_descanso =
                response.data.errores.tiempo_descanso_fecha2;
              this.msghorafinal_tiempo_descanso =
                response.data.errores.tiempo_descanso_hora2;
              break;

            default:
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.error,
                color: "red",
              });
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    actualizarTiempoExtra(formData) {
      EventServices.actualizarTiempoExtra(formData)
        .then((response) => {
          console.log(response);
          switch (response.data.mensaje) {
            case "guardado":
              this.cerrarDialog();
              this.$emit("actualizar");
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;

            case "errorDeValidacion":
              this.msgTiempoExtra = response.data.errores.tiempo_extra;
              break;

            default:
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.error,
                color: "red",
              });
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    actualizarFechaSalida(formData) {
      EventServices.actualizarFechaSalida(formData)
        .then((response) => {
          console.log(response);
          switch (response.data.mensaje) {
            case "guardado":
              this.cerrarDialog();
              this.$emit("actualizar");
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;

            case "errorDeValidacion":
              this.msgFechaSalida = response.data.errores.fecha_salida;
              this.msgHoraSalida = response.data.errores.hora_salida;
              break;

            default:
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.error,
                color: "red",
              });
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    actualizarFechaRSalida(formData) {
      EventServices.actualizarFechaRSalida(formData)
        .then((response) => {
          console.log(response);
          switch (response.data.mensaje) {
            case "guardado":
              this.cerrarDialog();
              this.$emit("actualizar");
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;

            case "errorDeValidacion":
              this.msgFechaRSalida = response.data.errores.fr_salida;
              this.msgHoraRSalida = response.data.errores.tr_salida;
              break;

            default:
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.error,
                color: "red",
              });
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    actualizarFechaRLlegada(formData) {
      EventServices.actualizarFechaRLlegada(formData)
        .then((response) => {
          console.log(response);
          switch (response.data.mensaje) {
            case "guardado":
              this.cerrarDialog();
              this.$emit("actualizar");
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;

            case "errorDeValidacion":
              this.msgFechaRLlegada = response.data.errores.fr_llegada;
              this.msgHoraRLlegada = response.data.errores.tr_llegada;
              break;

            default:
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.error,
                color: "red",
              });
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    tipoAccion() {
      console.log(this.tipoAccion);
    },
  },
};
</script>
