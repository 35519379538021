<template>
  <div class="bg" style="height: 100vh; margin-bottom: -10%">
    <v-main>
      <v-card
        :disabled="disabled"
        :loading="loading"
        outlined
        class="mx-auto mt-16"
        max-width="420px"
        height="540px"
      >
        <div class="text-center mt-10 pt-5">
          <h2>Gerenciamientos de viaje</h2>
          <v-row class="mt-1">
            <v-col cols="12" align="center">
              <v-img
                class="animate__animated animate__heartBeat animate__infinite despacio"
                src="@/assets/GMGL.png"
                width="45px"
              >
              </v-img>
            </v-col>
          </v-row>
        </div>
        <v-card-actions class="mt-1 pl-5 pr-5 pt-5">
          <v-container>
            <form v-on:submit.prevent="iniciarSesion">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    class="mb-5"
                    autofocus
                    v-model="usuario"
                    label="Correo Electronico"
                    hint="ejemplo@gruasmoviles.mx"
                    persistent-hint
                    outlined
                    :error-messages="errorUsuario"
                  ></v-text-field>
                  <v-text-field
                    class="mb-5"
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    label="Contraseña"
                    outlined
                    :error-messages="errorPassword"
                  ></v-text-field>
                  <a href="">¿Buscas la Ficha Técnica?</a>
                </v-col>
              </v-row>
              <div
                style="right: 0; position: absolute; display: inline"
                class="pl-5 pr-9 pt-5"
              >
                <v-btn color="primary" large type="submit">
                  Iniciar Sesión
                </v-btn>
                <br />
              </div>
            </form>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-main>
  </div>
</template>

<script lang="js">
import {
    mapMutations
} from "vuex";
import EventServices from "@/js/EventServices.js";

export default {
    name: "Login",
    data: () => ({
        loading: false,
        disabled: false,
        show1: false,
        usuario: "",
        password: "",
        errorUsuario: "",
        errorPassword: "",
    }),
    mounted() {
        const datauser = localStorage.getItem('userData');
        if (datauser) {
          let dataparse = JSON.parse(datauser)
            this.DATOS_USUARIOS_MUTATION(dataparse.data.data);
            this.TOKEN_MUTATION(dataparse.data.token);
            this.FOTO_MUTATION(dataparse.data.foto);
            this.LOGGED_IN_MUTATION();
            this.$router.push("/aplicaciones");
        } else {
      localStorage.removeItem('userData');
            this.CLOSE_LOGIN_MUTATION();
            this.$router.push("/");
        }
    },
    methods: {
        ...mapMutations([
            "SNACKBAR_MUTATION_MOSTRAR",
            "SNACKBAR_MUTATION_OCULTAR",
            "LOGGED_IN_MUTATION",
            "TOKEN_MUTATION",
            "CLOSE_LOGIN_MUTATION",
            "DATOS_USUARIOS_MUTATION",
            "FOTO_MUTATION",
        ]),

        async iniciarSesion() {
            this.loading = true;
            this.disabled = true;
            let datosLogin = {
                correo: this.usuario,
                password: this.password,
            };
            await EventServices.IniciarSesion(datosLogin)
                .then((response) => {
                    switch (response.data.mensaje) {
                        case "todoBien":
                            localStorage.setItem('userData', JSON.stringify(response.data));
                            this.DATOS_USUARIOS_MUTATION(response.data.data);
                            this.TOKEN_MUTATION(response.data.token);
                            this.FOTO_MUTATION(response.data.foto);
                            this.LOGGED_IN_MUTATION();
                            this.$router.push("/aplicaciones");
                            break;
                        case "errorDeValidacion":
                            this.errorUsuario = response.data.errores.correo;
                            this.errorPassword = response.data.errores.password;
                            break;
                        default:
      localStorage.removeItem('userData');
                            this.CLOSE_LOGIN_MUTATION();
                            break;
                    }
                })
                .catch(function (error) {
                    this.SNACKBAR_MUTATION_MOSTRAR({
                        titulo: error,
                        color: "red"
                    });
                })
                .finally(() => {
                    this.loading = false;
                    this.disabled = false;
                });
        },
    },
};
</script>

<style scoped>
.despacio {
  --animate-duration: 6s;
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../assets/tractodos.jpeg") no-repeat center center;
  background-size: cover;
  transform: scale(1.1);
}
</style>
