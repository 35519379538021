import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'animate.css';
import moment from 'moment'
import VueExcelEditor from 'vue-excel-editor'
// import { Autorizar } from "@/js/Helpers.js";
import ExportImage from './js/ExportImage'

Vue.prototype.moment = moment
Vue.config.productionTip = false

Vue.mixin({
  methods: {
    autorizarGlobal: function (permiso) {
      let menu = store.state.submenu;
      let id = store.state.idsubmenu;
      const data = menu.filter((e) => e.id === id);
      let res = data[0].permisos.some((p) => p === permiso.value)
      return res;
    },
    exportarImagen: function (imagen) {
      let data = ExportImage(imagen)
      return data;
    }
  }
});

Vue.use(VueExcelEditor)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
