<template>
  <div>
    <v-dialog
      v-model="stateDialogControlCambios"
      width="700"
      @click:outside="cerrarDialog"
      @keydown.esc="cerrarDialog"
    >
      <v-card height="70vh">
        <v-card-title class="text-h6 grey lighten-2"> Control de Versiones </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-group
              class="list-item-class"
              v-for="item in items"
              :key="item.title"
              v-model="item.active"
              :prepend-icon="item.action"
              no-action
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.fecha"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-divider></v-divider>
              <div v-for="i in item.items" :key="i.id" class="items-body">
                <div class="items-body-content">
                  <li>
                    <span>{{ i.descripcion }}</span>
                  </li>
                </div>
              </div>
              <v-divider></v-divider>
            </v-list-group>
          </v-list>
        </v-card-text>
        <v-footer class="pa-2">
          <div class="mx-auto">
            <v-btn color="green darken-1" @click="cerrarDialog" text :loading="loading">
              <v-icon>mdi-logout</v-icon>Salir
            </v-btn>
          </div>
        </v-footer>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped lang="scss">
$pri: #128cfc;
$sec: #fffffe;
$dark-pri: #0b5aa2;
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.items {
  width: 300px;
  background: $sec;
  box-shadow: 0 3px 6px rgba(black, 0.16), 0 3px 6px rgba(black, 0.23);
  border-top: 10px solid $dark-pri;
}

.items-head p {
  padding: 5px 20px;
  margin: 10px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 20px;
}

.items-head hr {
  width: 20%;
  margin: 0px 30px;
  border: 1px solid $dark-pri;
}

.items-body {
  display: grid;
  grid-gap: 10px;
}

.list-item-class {
  border: #c5c5c5 1px solid;
  margin-bottom: 10px;
}

.items-body-content {
  color: #535353;
  text-align: justify;
  padding: 5px;
  padding-right: 0px;
  display: grid;
  grid-template-columns: 10fr 1fr;
  font-size: 13px;
  grid-gap: 10px;
  border: 1px solid transparent;
  cursor: pointer;
}

.items-body-content i {
  align-self: center;
  font-size: 15px;
  font-weight: bold;
  animation: icon 1.5s infinite forwards;
}

@keyframes icon {
  0%,
  100% {
    transform: translate(0px);
  }
  50% {
    transform: translate(3px);
  }
}
</style>
<script>
export default {
  name: "ControlCambios",
  props: {
    stateDialogControlCambios: Boolean,
  },
  data: () => ({
    items: [
      {
        title: "versión 2.3.0",
        fecha: "22 de Mayo de 2023",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "Se añade opcion para solicitar cotizaciones desde https://cotizar.gruasmoviles.com.mx",
          },
          {
            descripcion:
              "Se añade opcion para administrar las cotizaciones en la sección de Ventas",
          },
        ],
      },
      {
        title: "versión 2.2.0",
        fecha: "10 de Marzo de 2023",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "Se añade opcion para administrar documentación de operadores y vehiculos.",
          },
          {
            descripcion:
              "Se añade opcion para administrar placas de los vehiculos en la sección de Logística.",
          },
        ],
      },
      {
        title: "versión 2.1.0",
        fecha: "28 de Febrero de 2023",
        action: "mdi-briefcase",
        items: [
          {
            descripcion: "Se rediseña sección de operadores y vehiculos.",
          },
          {
            descripcion: "Se añade opción para asignar operador a un vehiculo.",
          },
        ],
      },
      {
        title: "versión 2.0.0",
        fecha: "17 de Febrero de 2023",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se rediseña el sistema de gerenciamiento de viaje para tener un alcance extendido a otras áreas.",
          },
          {
            descripcion:
              "2- Se agrega un nuevo sistema de administración de roles y permisos.",
          },
          {
            descripcion:
              "3- Se añade sección para departamento de ventas para administrar Fichas Técnicas.",
          },
        ],
      },
      {
        title: "versión 1.7.0",
        fecha: "25 de Enero de 2023",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se rediseña la interfaz para administrar los servicios locales.",
          },
          {
            descripcion:
              "2- Se agrega opción para solicitar reapertura de un servicio terminado en gerenciamiento de viaje.",
          },
        ],
      },
      {
        title: "versión 1.6.0",
        fecha: "13 de Octubre de 2022",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se rediseña la interfaz para administrar los servicios locales.",
          },
          {
            descripcion: "2- Cambios en diseño de interfaces general.",
          },
        ],
      },
      {
        title: "versión 1.5.1",
        fecha: "07 de Septiembre de 2022",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se añade seguridad de JWT para descargar de manera segura los pdf del gerenciamiento de viaje.",
          },
          {
            descripcion:
              "2- Cambios en diseño de ventana para mostrar información del servicio local.",
          },
        ],
      },
      {
        title: "versión 1.5.0",
        fecha: "05 de Septiembre de 2022",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se añade opcion para agregar evidencias de malas operaciones a un servicio.",
          },
          {
            descripcion:
              "2- Se añade opción para agregar tiempo extra, tiempo perdido y tiempo de descanso.",
          },
          {
            descripcion:
              "3- Se hacen cambios en diseño en Nuevo Gerenciamiento, Locales y Dashboard.",
          },
        ],
      },
      {
        title: "versión 1.4.0",
        fecha: "04 de Agosto de 2022",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se agrega reporte para obtener gerenciamientos sin bitacora.",
          },
          {
            descripcion:
              "2- Se hacen cambios menores de diseño en la interfaz de información del gerenciamiento de viaje.",
          },
        ],
      },
      {
        title: "versión 1.3.1",
        fecha: "04 de Julio de 2022",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se agrega campo de texto para añadir observaciones al servicio",
          },
          {
            descripcion:
              "2- Se hacen cambios menores de diseño en la interfaz de información del gerenciamiento de viaje",
          },
        ],
      },
      {
        title: "versión 1.3.0",
        fecha: "16 de Junio de 2022",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se agregan los reportes de servicios locales, se filtran por operador, cliente, vehiculo y rutas.",
          },
        ],
      },
      {
        title: "versión 1.2.0",
        fecha: "11 de Junio de 2022",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se agregan validaciones en interfaz los gerenciamientos de viaje para evitar cambios no deseados por otros usuarios que no sean de CMC",
          },
          {
            descripcion:
              "2- Se agrega opcion para registrar y eliminar checkpoints en las rutas de un servicio.",
          },
          {
            descripcion:
              "3- Se agrega opcion para registrar y eliminar evidencias en un checkpoint",
          },
          {
            descripcion:
              "4- Se corrige la funcion para actualizar el Kilometraje recorrido en un servicio",
          },
        ],
      },
      {
        title: "versión 1.1.1",
        fecha: "09 de Junio de 2022",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se agrega campo de texto para modificar el folio de un servicio.",
          },
          {
            descripcion:
              "2- Se agrega campo de texto en el gerenciamiento de viaje para registrar KM recorridos.",
          },
        ],
      },
      {
        title: "versión 1.1.0",
        fecha: "07 de Junio de 2022",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Sección de reportes con reportes generales y otros, los reportes se filtran por operador, cliente, vehiculo y rutas.",
          },
        ],
      },
      {
        title: "versión 1.0.0",
        fecha: "06 de Junio de 2022",
        action: "mdi-briefcase",
        items: [
          {
            descripcion:
              "1- Se sube la primera versión del proyecto, esta contiene operadores, vehiculos, usuarios, rutas, gerenciamiento de viaje foraneos, locales.",
          },
        ],
      },
    ],
    loading: false,
    desde: null,
    hasta: null,
  }),

  methods: {
    cerrarDialog: function () {
      this.$emit("cerrarModal", false);
    },
  },
};
</script>
