<template>
  <div class="text-center">
    <v-dialog
      @keydown.esc="cerrarDialog"
      v-model="stateDialogMO"
      width="790"
      @click:outside="cerrarDialog"
      :loading="loadingDialog"
    >
      <v-card class="rounded-0">
        <v-card-title class="text-h6 grey lighten-2"
          >Agregar evidencia de Mala Operación</v-card-title
        >
        <div class="flex" v-if="autorizar('Agregar')">
          <div class="row mt-4 mr-1 ml-1">
            <v-col cols="12" sm="4">
              <v-text-field label="Titulo" dense v-model="titulo"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="Link de evidencia"
                dense
                v-model="linkEvidencia"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete
                v-model="autocompletValue"
                :items="autocompletItems"
                dense
                label="Tipo"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="1">
              <v-btn
                fab
                small
                color="primary"
                @click="agregar"
                :disabled="
                  tipoMatriz != 'EnProceso' || (rol != 'Administrador' && rol != 'CMC')
                    ? true
                    : false
                "
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </div>
        </div>
        <div class="ma-5">
          <div class="col-12">
            <v-simple-table fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Titulo</th>
                    <th>Evidencia</th>
                    <th>Tipo</th>
                    <th width="35" v-if="autorizar('Eliminar')">Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in ListaEvidencias" :key="item.id">
                    <td width="600px">
                      {{ item.titulo }}
                    </td>
                    <td width="600px">
                      {{ item.tipo_mala_operacion }}
                    </td>
                    <td>
                      <v-btn
                        class="ma-1 white--text"
                        :href="item.link"
                        target="_blank"
                        color="cyan"
                      >
                        <v-icon> mdi-link-variant </v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        v-if="autorizar('Agregar')"
                        fab
                        small
                        color="primary"
                        @click="eliminarEvidencia(item.id)"
                        :disabled="
                          tipoMatriz != 'EnProceso' || rol === 'logistica' ? true : false
                        "
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text type="submit" @click="cerrarDialog"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventServices from "@/js/EventServices.js";
import { mapMutations } from "vuex";
import Mensajes from "@/js/Mensajes.js";

import Mixin from "@/js/Mixin.js";
export default {
  mixins: [Mixin],
  name: "DialogAddMalaOperacion",

  props: {
    stateDialogMO: Boolean,
    datosEditar: [Object, Array],
    tipoMatriz: String,
    // tipo: String,
  },

  data: () => ({
    rol: null,
    loadingDialog: false,
    ListaEvidencias: [],
    msgurl: "",
    tab: null,
    loadingBoton: false,

    titulo: null,
    linkEvidencia: null,
    autocompletValue: null,
    autocompletItems: ["Exceso de velocidad", "Paradas no autorizadas", "Relentin"],
  }),

  mounted() {
    this.rol = this.$store.state.datosUsuariosState.rolS;
  },

  methods: {
    ...mapMutations(["SNACKBAR_MUTATION_MOSTRAR"]),
    cerrarDialog: function () {
      this.$emit("cerrarDialogMP", false);
    },

    vaciarDatos: function () {
      this.titulo = null;
      this.linkEvidencia = null;
      this.autocompletValue = null;
    },

    buscarDatos: function () {
      this.loadingDialog = true;
      EventServices.buscarEvidencias(this.datosEditar.id)
        .then((response) => {
          console.log("lista mala operacion", response.data);
          this.ListaEvidencias = response.data.datos;
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },

    agregar: function () {
      this.loadingDialog = true;
      const datos = {
        titulo: this.titulo,
        linkEvidencia: this.linkEvidencia,
        tipo: this.autocompletValue,
        idGerencia: this.datosEditar.id,
      };
      EventServices.guardarEvidenciaMalaOperacion(datos)
        .then((response) => {
          if (response.data.mensaje == "guardado") {
            this.buscarDatos();
            this.SNACKBAR_MUTATION_MOSTRAR({
              titulo: Mensajes.success,
              color: "success",
            });
            this.vaciarDatos();
          } else {
            this.SNACKBAR_MUTATION_MOSTRAR({
              titulo: Mensajes.error,
              color: "red",
            });
          }
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({ titulo: error, color: "red" });
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },

    eliminarEvidencia: function (id) {
      this.loadingDialog = true;
      EventServices.eliminarEvidenciaMalaOperacion(id)
        .then((response) => {
          switch (response.data.mensaje) {
            case "eliminado":
              this.buscarDatos();
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.delete,
                color: "success",
              });
              break;
          }
        })
        .catch((error) => {
          this.SNACKBAR_MUTATION_MOSTRAR({ titulo: error, color: "red" });
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },
  },

  watch: {
    stateDialogMO: function () {
      this.buscarDatos();
    },
  },
};
</script>
