import { switchCase } from './Helpers';
import administracion from '@/assets/secciones/administracion.png';
import cmc from '@/assets/secciones/cmc.png';
import controlversiones from '@/assets/secciones/controlversiones.png';
import logistica from '@/assets/secciones/logistica.png';
import mantenimiento from '@/assets/secciones/mantenimiento.png';
import reportes from '@/assets/secciones/reportes.png';
import ventas from '@/assets/secciones/ventas.png';
import calidad from '@/assets/secciones/calidad.png';

import roles from '@/assets/secciones/roles.png';
import usuarios from '@/assets/secciones/usuarios.png';
import publicaciones from '@/assets/secciones/publicaciones.png';

import dashboard from '@/assets/secciones/dashboard.png';
import gerenciamientos from '@/assets/secciones/gerenciamiento.png';
import nuevogerenciamiento from '@/assets/secciones/nuevogerenciamiento.png';
import servicioslocales from '@/assets/secciones/servicioslocales.png';
import rutas from '@/assets/secciones/rutas.png';


import operadores from '@/assets/secciones/operadores.png';
import vehiculos from '@/assets/secciones/vehiculos.png';
import documentospermisos from '@/assets/secciones/documentospermisos.png';


import fichatecnica from '@/assets/secciones/fichatecnica.png';
import clientes from '@/assets/secciones/clientes.png';
import cotizaciones from '@/assets/secciones/cotizaciones.png';

import reportes2 from '@/assets/secciones/reportes2.png';

import manttosolicitado from '@/assets/secciones/manttosolicitado.png';

import contabilidad from '@/assets/secciones/contabilidad.png';
import controlMovil from '@/assets/secciones/controlMovil.png';

import facturacion from '@/assets/secciones/facturacion.png';
import rutasbases from '@/assets/secciones/rutasbases.png';

const ExportImage = (img) => {
    return switchCase(img, {
        'administracion':       () => administracion,
        'cmc':                  () => cmc,
        'controlversiones':     () => controlversiones,
        'logistica':            () => logistica,
        'mantenimiento':        () => mantenimiento,
        'reportes':             () => reportes,
        'ventas':               () => ventas,
        'calidad':              () => calidad,

        'roles':                () => roles,
        'usuarios':             () => usuarios,
        'publicaciones':        () => publicaciones,

        'dashboard':            () => dashboard,
        'gerenciamientos':      () => gerenciamientos,
        'nuevogerenciamiento':  () => nuevogerenciamiento,
        'servicioslocales':     () => servicioslocales,
        'rutas':                () => rutas,

        'operadores':           () => operadores,
        'vehiculos':            () => vehiculos,
        'documentospermisos':   () => documentospermisos,
        
        'fichatecnica':         () => fichatecnica,
        'clientes':             () => clientes,
        'cotizaciones':         () => cotizaciones,

        'reportes2':            () => reportes2,
        'manttosolicitado':     () => manttosolicitado,

        'contabilidad':         () => contabilidad,
        'controlMovil':         () => controlMovil,
        
        'facturacion':          () => facturacion,
        'rutasbases':           () => rutasbases,
    })
}
export default ExportImage;