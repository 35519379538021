<template>
  <div class="text-center">
    <v-dialog
      @keydown.esc="cerrarDialog"
      v-model="stateDialogEviCheck"
      width="800"
      @click:outside="cerrarDialog"
    >
      <v-card class="rounded-0" :loading="loadingDialog" :disabled="loadingDialog">
        <v-card-title class="text-h6 grey lighten-2">
          Agregar Evidencias al checkpoint
        </v-card-title>
        <div class="ma-5 row">
          <div v-if="autorizar('Agregar')" class="col-12">
            <form ref="guardarEvento" @submit.prevent="guardarEvidencia">
              <v-row align="center" justify="space-around" class="row">
                <input :value="datosEditar.id" hidden name="id_checkpoint" />
                <v-text-field
                  label="Titulo"
                  name="titulo"
                  v-model="txtTitulo"
                  v-on:keypress="msgTitulo = ''"
                  :error-messages="msgTitulo"
                  class="ml-2 mr-2"
                ></v-text-field>
                <v-text-field
                  label="Link de Evidencia"
                  name="evidencia"
                  v-model="txtEvidencia"
                  v-on:keypress="msgEvidencia = ''"
                  :error-messages="msgEvidencia"
                  class="ml-2 mr-2"
                ></v-text-field>
                <v-btn depressed class="ml-3" type="submit"> Agregar </v-btn>
              </v-row>
            </form>
          </div>
          <div class="col-12">
            <v-simple-table v-if="ListaEvidencias.length > 0" fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Titulo</th>
                    <th>Evidencia</th>
                    <th width="35" v-if="autorizar('Eliminar')">Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in ListaEvidencias" :key="item.id">
                    <td width="600px">
                      {{ item.titulo }}
                    </td>
                    <td>
                      <v-btn
                        class="ma-1 white--text"
                        :href="item.evidencia"
                        target="_blank"
                        color="cyan"
                      >
                        <v-icon> mdi-link-variant </v-icon>
                      </v-btn>
                    </td>
                    <td v-if="autorizar('Eliminar')">
                      <v-icon
                        :disabled="soloConsulta"
                        @click="eliminarEvidencia(item.id)"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>

        <v-divider></v-divider>
        <v-card-actions>
          <span
            >Nota: No se puede modificar o eliminar evidencias una vez que se finaliza la
            tarea</span
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cerrarDialog"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventServices from "@/js/EventServices.js";
import { mapMutations } from "vuex";
import Mensajes from "@/js/Mensajes.js";

import Mixin from "@/js/Mixin.js";
export default {
  mixins: [Mixin],
  name: "DialogAddEvidenciaCheck",

  props: {
    stateDialogEviCheck: Boolean,
    datosEditar: Object,
    soloConsulta: Boolean,
  },

  data: () => ({
    loadingDialog: false,
    msgTitulo: "",
    msgEvidencia: "",
    msgurl: "",
    txtTitulo: "",
    txtEvidencia: "",
    ListaEvidencias: [],
  }),
  methods: {
    ...mapMutations(["SNACKBAR_MUTATION_MOSTRAR"]),
    cerrarDialog: function () {
      this.$emit("cerrarDialog", false);
      this.limpiarMensajesDeError();
    },
    limpiarMensajesDeError: function () {
      this.msgurl = "";
    },
    obtenerEvidencias() {
      this.loadingDialog = true;
      EventServices.listarEvidenciasCheck(this.datosEditar.id)
        .then((response) => {
          console.log(response.data.datos);
          this.loadingDialog = false;
          this.ListaEvidencias = response.data.datos;
        })
        .catch((error) => {
          this.loadingDialog = false;
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: error,
            color: "red",
          });
        });
    },
    eliminarEvidencia: function (id) {
      EventServices.eliminarEvidenciaCheck(id)
        .then((response) => {
          console.log("res", response);
          switch (response.data.mensaje) {
            case "eliminado":
              this.loadingDialog = false;
              this.obtenerEvidencias();
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.delete,
                color: "success",
              });
              break;
          }
        })
        .catch((error) => {
          this.loadingDialog = false;
          this.SNACKBAR_MUTATION_MOSTRAR({ titulo: error, color: "red" });
        });
    },
    guardarEvidencia: function () {
      this.loadingDialog = true;
      var formData = new FormData(this.$refs.guardarEvento);
      EventServices.guardarEvidenciaCheck(formData)
        .then((response) => {
          switch (response.data.mensaje) {
            case "guardado":
              this.loadingDialog = false;
              this.obtenerEvidencias();
              this.txtTitulo = "";
              this.txtEvidencia = "";

              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;

            case "errorDeValidacion":
              this.loadingDialog = false;
              this.msgTitulo = response.data.errores.titulo;
              this.msgEvidencia = response.data.errores.evidencia;
              break;
          }
        })
        .catch((error) => {
          this.loadingDialog = false;
          this.SNACKBAR_MUTATION_MOSTRAR({ titulo: error, color: "red" });
        });
    },
  },
  watch: {
    stateDialogEviCheck: function () {
      this.obtenerEvidencias();
    },
  },
};
</script>
