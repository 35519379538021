import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: colors.blue.darken4, //
                secondary: colors.red.lighten1, // #FFCDD2
                accent: colors.indigo.base, // #3F51B5
                amarillo: '#F9BB00',
                verde: colors.green.darken1, //#4CAF50
                gris: '#8E8E8E',
                rojo: '#E2241F',
            },
        },
    },
    lang: {
        locales: {
            es
        },
        current: 'es',
    },
});