<template>
  <v-app id="inspire">
    <div v-if="transicion" class="text-center">
      <v-overlay class="bg2" :value="transicion">
        <v-progress-circular
          style="color: #f9bb00"
          class="gmglogo"
          indeterminate
          size="200"
        >
        </v-progress-circular>
      </v-overlay>
    </div>
    <div v-else>
      <v-app-bar
        :app="!transicion"
        dark
        class="amarillo"
        :clipped-left="$vuetify.breakpoint.lgAndUp"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon
              class="mr-5 black--text"
              v-bind="attrs"
              v-on="on"
              @click="volverMenu()"
            >
              <v-icon size="40px" v-bind="attrs" v-on="on">
                mdi-arrow-left-bold
              </v-icon>
            </v-app-bar-nav-icon>
          </template>
          <span>Volver</span>
        </v-tooltip>
        <div class="mr-5">
          <v-img src="@/assets/GMGL.png" width="40px"></v-img>
        </div>
        <v-toolbar-title
          style="font-size: 20px"
          class="black--text font-weight-bold"
          >SEAL GMG</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" x-large plain @click="cerrarSesion">
              <v-icon size="30px" :large="isMobile" color="black">
                mdi-logout
              </v-icon>
            </v-btn>
          </template>
          <span>Cerrar Sesión</span>
        </v-tooltip>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </v-container>
      </v-main>
      <SheetInfoGerenciamiento
        :tipoMatriz="tipoMatriz"
        :stateSheetInfo="stateSheetInfo"
        :datosEditar="datosEditar"
        @cerrarSheetInfo="cerrarModales"
      />
      <DialogCerrarSesion
        v-if="stateDialogCerrarSesion"
        :stateDialog="stateDialogCerrarSesion"
        @cerrarModal="cerrarDialog"
      />
    </div>
  </v-app>
</template>

<script>
import { mapMutations } from "vuex";
import EventServices from "@/js/EventServices.js";
import SheetInfoGerenciamiento from "@/components/Gerenciamientos/SheetInfoGerenciamiento";
import DialogCerrarSesion from "@/components/DialogCerrarSesion";
import store from "@/store";

export default {
  name: "User",
  components: {
    SheetInfoGerenciamiento,
    DialogCerrarSesion,
  },
  data: () => ({
    isMobile: false,
    tipoMatriz: "",
    stateSheetInfo: false,
    datosEditar: {},
    transicion: true,
    efecto: false,
    drawer: false,
    selectedItem: 1,
    conteo: null,
    contador: false,
    notificacion: 0,
    notificaciones: [],
    stateDialogCerrarSesion: false,
    rol: store.state.datosUsuariosState.rolS,
  }),
  mounted() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // this.buscarGerenciamientos();
    let time = setInterval(() => {
      this.transicion = false;
      clearInterval(time);
    }, 200);
    // this.intervalo = setInterval(() => {
    //   this.buscarGerenciamientos();
    // }, 10000);
  },
  methods: {
    ...mapMutations(["CLOSE_LOGIN_MUTATION", "MENU_MUTATION_MOSTRAR"]),
    volverMenu: function () {
      this.$router.push("/menu");
    },
    cerrarModales: function (resCompDialog) {
      this.stateSheetInfo = resCompDialog;
    },
    async mostrarNotificacion(item) {
      this.stateSheetInfo = true;
      let items = {
        id: item.gerenciamiento,
      };
      this.datosEditar = items;
      this.tipoMatriz = "EnProceso";
    },
    async buscarGerenciamientos() {
      await EventServices.buscarGerenciamientos().then((res) => {
        this.contador = true;
        this.conteo = res.data.conteo;
        this.notificaciones = res.data.notificaciones;
      });
    },
    async marcarLeido(item) {
      await EventServices.marcarLeido(item).then(() => {
        this.buscarGerenciamientos();
      });
    },
    ruta: function (ruta) {
      this.$router.push(ruta).catch(() => {});
    },
    cerrarSesion: function () {
      this.stateDialogCerrarSesion = true;
    },
    cerrarDialog: function (props) {
      this.stateDialogCerrarSesion = props;
    },
  },

  watch: {
    drawer: function (estado) {
      this.MENU_MUTATION_MOSTRAR(estado);
    },
  },
};
</script>

<style scoped>
.bg2 {
  height: 95vh;
  margin-bottom: -100px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../assets/fondo-camion.png") no-repeat center center;
  background-size: cover;
  transform: scale(1.1);
}
.gmglogo {
  background: url("../../assets/GMGL.png") no-repeat center center;
  background-size: cover;
  transform: scale(1.1);
}
</style>
