import store from "@/store";
const KEY_CRYPTO = 'GMGKEYGERENCIAMIENTODEVIAJE2023';

export function pathCrypt(_text) {
    const text = _text.toString();
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).slice(-2); //substr a slice DEPRECATE-substr
    const applySaltToChar = (code) =>
        textToChars(KEY_CRYPTO).reduce((a, b) => a ^ b, code);

    return text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
}

export function pathDecrypt(_encoded) {
    const encoded = _encoded.toString();
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
        textToChars(KEY_CRYPTO).reduce((a, b) => a ^ b, code);

    return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
}

export function Permisos(roles) {
    const autorizado = roles.some((element) => element === store.state.datosUsuariosState.rolS);
    return autorizado;
}

export function Autorizar(permiso) {
    let menu = store.state.submenu;
    let id = store.state.idsubmenu;
    const data = menu.filter((e) => e.id === id);
    console.log('permisos?', data)
    let res = data[0].permisos.some((p) => p === permiso.value)
    return res;
}

export function CheckBoxData(tipo, asignadosbd, selecteds) {
    if (tipo === 'add') {
        let arrayBD = [];
        let arrayAgregar = [];
        for (let bd = 0; bd < asignadosbd.length; bd++) {
            const element = asignadosbd[bd];
            arrayBD.push(element)
        }
        for (let s = 0; s < selecteds.length; s++) {
            const element = selecteds[s];
            let find = arrayBD.indexOf(element);
            if (find === -1) {
                arrayAgregar.push(element)
            }
        }
        return arrayAgregar;
    }
    if (tipo === 'delete') {
        let arraySelect = [];
        let arrayEliminar = [];
        for (let s = 0; s < selecteds.length; s++) {
            const element = selecteds[s];
            arraySelect.push(element)
        }
        for (let s = 0; s < asignadosbd.length; s++) {
            const element = asignadosbd[s];
            let find = arraySelect.indexOf(element);
            if (find === -1) {
                arrayEliminar.push(element)
            }
        }
        return arrayEliminar;
    }
}

export function estilosMenuApps(hover, seccion) {
    let style = "";
    if (hover) {
        if (seccion.autorizado) {
            style = "background-color: #F9BB0090;cursor: pointer";
        } else {
            style = "background-color: #00000050; cursor: not-allowed;";
        }
    } else {
        if (seccion.autorizado) {
            style = "background-color: #ffffff80";
        } else {
            style = "background-color: #00000050";
        }
    }
    return style;
}

export function switchCase(response, options) {
    options["DEFAULT"] = () => console.log('error');
    let result = options[response] ? options[response]() : options["DEFAULT"]();
    return result;
}