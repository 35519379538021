export default {
  success: "Guardado",
  delete: "Registro Eliminado",
  alta: "Registro Dado de Alta",
  baja: "Registro Dado de Baja",
  cancelar: "Registro Cancealado",
  apertura: "Registro abierto",
  update: "Registro Actualizado",
  error: "Ha ocurrido un error inesperado, recargue la pagina he intente de nuevo",
  errorTerminarGerencia: "No se ha podido terminar el gerenciamiento debido a que aun tiene checkpoints sin verificar",
  imgNoValido: "La imagen no es valida, verifique que tenga extención .gif, .jpeg, .jpg, .png",
  errorImg: "Sube una foto Valida",
  existeCorreo: "El correo electrónico ya esta registrado",
  errorClave: "La contraseña no coincide con la contraseña anterior",
  correoEnviado: "Se ha enviado un correo con su nueva contraseña",
  usuarioBaja: "Usuario dado de baja",
  successMoverTareas: "Las tareas seleccionadas han sido traspasadas",
  errorMoverTareas: "No hay tareas para mover",
  sinEvidencia: "Agregue por lo menos una evidencia",
  iguales: "EL operador y compañero no pueden ser el mismo",
  errorDestino: "El destino de la ruta no tiene tiempo de recorrido",
  errorRuta: "El destino de la ruta no tiene kilometros totales",
  vehiculoEnServicio: "El vehiculo esta en servicio",
  VehiculoEnReparacion: "El vehiculo se encuentra en reparación, no se puede asignar operador",
  operadorEnServicio: "El operador esta en servicio",
  errorFechasVaciasLocales: "Agrege todas las fechas necesarias antes de finalizar el servicio",
  pdfBase: "",
  operadorNoDescansado: 'El operador no ha descansando las horas suficientes, necesita autorizacion',
  existePedido: 'El numero del pedido ya existe',
  PermisosActualizados: 'Los permisos de este rol han sido actualizados',
  OperadorYaAsignado: 'Este operador ya ha sido asignado a otra unidad',
};
