<template>
  <div class="text-center">
    <v-dialog
      v-model="stateDialogConfirmar"
      width="500"
      @click:outside="cerrarDialog"
      @keydown.esc="cerrarDialog"
    >
      <v-card class="rounded-0">
        <v-card-title v-if="tipo === 'terminar'" class="text-h6 lighten-2"
          >¿Desea terminar el siguiente gerenciamiento?</v-card-title
        >

        <v-card-title v-if="tipo === 'cancelar'" class="text-h6 lighten-2"
          >¿Desea cancelar el siguiente gerenciamiento?</v-card-title
        >
        <v-card-title
          v-if="tipo === 'apertura' && datosShow.pedido_apertura === 'SI'"
          class="text-h6 lighten-2"
          >ya existe la solicitud de apertura en este servicio</v-card-title
        >
        <v-card-title
          v-if="tipo === 'apertura' && datosShow.pedido_apertura === 'NO'"
          class="text-h6 lighten-2"
          >¿Desea solicitar la apertura de este servicio?</v-card-title
        >
        <form ref="guardarDatos" @submit.prevent="submitDialog">
          <input :value="datosShow.id" hidden name="id" />
          <v-text-field
            type="date"
            class="ma-5"
            v-if="tipo === 'terminar'"
            v-model="Fecha_terminoServicio"
            label="Fecha de Termino del Servicio"
            name="fecha_termino_servicio"
            outlined
            v-on:keypress="fterminoservicio = ''"
            :error-messages="fterminoservicio"
          >
          </v-text-field>
          <v-text-field
            type="time"
            class="ma-5"
            v-if="tipo === 'terminar'"
            v-model="Hora_terminoServicio"
            label="Hora de Termino del Servicio"
            name="hora_termino_servicio"
            outlined
            v-on:keypress="hterminoservicio = ''"
            :error-messages="hterminoservicio"
          >
          </v-text-field>
          <v-text-field
            class="ma-5"
            v-if="tipo === 'terminar'"
            label="Kilometraje Llegada"
            name="km_recorridos"
            outlined
            v-on:keypress="msgkmrecorridos = ''"
            :error-messages="msgkmrecorridos"
          >
          </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" :disabled="loading" text @click="cerrarDialog"
              >Cancelar</v-btn
            >
            <v-btn
              v-if="tipo === 'terminar'"
              color="green darken-1"
              text
              type="submit"
              :loading="loading"
            >
              <v-icon>mdi-check</v-icon>Terminar
            </v-btn>
            <v-btn
              v-if="tipo === 'apertura' && datosShow.pedido_apertura === 'NO'"
              color="green darken-1"
              text
              type="submit"
              :loading="loading"
            >
              <v-icon>mdi-check</v-icon>Solicitar
            </v-btn>
            <v-btn
              v-if="tipo === 'cancelar'"
              @click="submitDialog"
              color="grey darken-1"
              text
              type="submit"
              :loading="loading"
            >
              <v-icon>mdi-delete</v-icon>Cancelar
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EventServices from "@/js/EventServices.js";
import Mensajes from "@/js/Mensajes.js";
import { mapMutations } from "vuex";
export default {
  name: "DialogConfirmar",
  props: {
    titulo: String,
    stateDialogConfirmar: Boolean,
    datosShow: Object,
    tipo: String,
  },
  data: () => ({
    Fecha_terminoServicio: null,
    Hora_terminoServicio: null,
    loading: false,
    loadingGuardarTerminoServicio: false,
    fterminoservicio: "",
    hterminoservicio: "",
    km_recorridos: null,
    msgkmrecorridos: "",
  }),
  methods: {
    ...mapMutations(["SNACKBAR_MUTATION_MOSTRAR"]),
    cerrarDialog: function () {
      this.$emit("cerrarDialogConfirmar", false);
    },
    submitDialog: function () {
      this.loading = true;
      console.log("loading true", this.loading);
      switch (this.tipo) {
        case "terminar":
          this.terminarGerenciamiento();
          break;

        case "cancelar":
          this.cancelarGerenciamiento();
          break;

        case "apertura":
          this.solicitarApertura();
          break;
        default:
          this.SNACKBAR_MUTATION_MOSTRAR({
            titulo: Mensajes.error,
            color: "red",
          });
          break;
      }
    },
    async terminarGerenciamiento() {
      var formData = new FormData(this.$refs.guardarDatos);
      await EventServices.terminarGerenciamiento(formData)
        .then((response) => {
          switch (response.data.mensaje) {
            case "terminado":
              this.cerrarDialog();
              this.$emit("actualizar");
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;
            case "error":
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.errorTerminarGerencia,
                color: "error",
              });
              break;
            default:
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.error,
                color: "red",
              });
              break;
          }
        })
        .finally(() => {
          this.loading = false;
          console.log("loading false", this.loading);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async cancelarGerenciamiento() {
      let id = this.datosShow.id;
      await EventServices.cancelarGerenciamiento(id)
        .then((response) => {
          switch (response.data.mensaje) {
            case "cancelado":
              this.cerrarDialog();
              this.$emit("actualizar");
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;
            default:
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.error,
                color: "red",
              });
              break;
          }
        })
        .finally(() => {
          this.loading = false;
          console.log("loading false", this.loading);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async solicitarApertura() {
      let id = this.datosShow.id;
      await EventServices.solicitudApertura(id)
        .then((response) => {
          switch (response.data.mensaje) {
            case "solicitado":
              this.cerrarDialog();
              this.$emit("actualizar");
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.success,
                color: "success",
              });
              break;
            default:
              this.SNACKBAR_MUTATION_MOSTRAR({
                titulo: Mensajes.error,
                color: "red",
              });
              break;
          }
        })
        .finally(() => {
          this.loading = false;
          console.log("loading false", this.loading);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {},
};
</script>
